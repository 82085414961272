import React, {FC} from 'react';
import cs from 'classnames';

import {googleDiskImageToWeb} from "shared/utils/googleDiskImageToWeb";
import {NftCardModel} from "shared/http/ApiModels";
import CardMock from "assets/images/winners/card-mock.png";

import {Month} from "./types";
import moment from "moment";

type Props = {
    item: NftCardModel | undefined;
    onClick: (id: string) => void;
    month: Month
}

export const MonthAwardee: FC<Props> = ({item, onClick, month}) => {
    const itemId = item?.id ?? item?._id;
    const isEmpty = !itemId;

    const handleClick = () => {
        if (itemId) {
            onClick(itemId);
        }
    };

    const imageSource = item?.avatarLink ? googleDiskImageToWeb(item.avatarLink) : CardMock;

    return (
        <div className="month-winners__card">
            <div className={cs({"month-winners__card-image": true, disable: isEmpty})}
                 onClick={handleClick}
            >
                <img
                    src={imageSource}
                    alt="image-month"/>
            </div>
            <div className="month-winners__card-title">
                {moment(month + 1, "M").format("MMMM")}
            </div>
        </div>
    );
};
