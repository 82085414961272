import React, {useCallback, useMemo} from "react";

import { useGalleryCenter } from "core/context/GalleryCenter";
import { useProfileCenter } from "core/context/ProfileCenter";
import { NftCardModel } from "shared/http/ApiModels";

import cn from "classnames";
import moment, { Moment } from "moment";

import { VoteCard } from "./components/VoteCard";

import "./VotePage.scss";
import {
    YEAR_END_VOTE_DATE,
    YEAR_START_VOTE_DATE,
    YEAR_VOTE_MONTH
} from "../MainPage/components/VoteBlock/VoteBlock.constants";

export const VotePage: React.FC = React.memo(() => {
  const [{ cardsForVote }, { fetchCardForVote }] = useGalleryCenter();
  const { voteCard, profile, votingCard } = useProfileCenter();

  const handleVote = useCallback(
    async (card: NftCardModel) => {
      await voteCard(card);
      await fetchCardForVote();
    },
    [voteCard, fetchCardForVote]
  );

        const isYearVote = useMemo(() => {
            const today = moment.utc();
            const month = today.month();
            const day = today.date();

            return month === YEAR_VOTE_MONTH && day >= YEAR_START_VOTE_DATE && day <= YEAR_END_VOTE_DATE;
        }, []);


    const getVotedCardId = useCallback(
    (voteDate: Moment) => {
      if (!profile) return undefined;
      if (isYearVote) {
        return profile.yearVotes.find(({ year, vote }) => {
          return year === moment.utc().subtract(1, "year").year() && vote;
        })?.vote?.id;
      }
      return profile?.votedCards?.find(({ date }) => moment.utc(date, "DD.MM.YYYY").month() === voteDate.month())?.id;
    },
    [profile, isYearVote]
  );

  const getCardVoteLeaderIds = useCallback((cards: NftCardModel[]) => {
    const cardsPercents = cards.map(({ votePercents }) => votePercents as number);
    const uniquePercentNumber = new Set(cardsPercents);
    let maxPercentVotes = 0;

    uniquePercentNumber.forEach((item) => {
      if (item > maxPercentVotes) {
        maxPercentVotes = item;
      }
    });

    return cards.filter(({ votePercents }) => votePercents === maxPercentVotes).map(({ id }) => id);
  }, []);

  return (
    <div className="vote-page text-page">
      <div className="vote-page__header text-page__header">
        <div className="text-page__title text-border">vote</div>
      </div>
      {!cardsForVote?.length && (
        <div
          style={{
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "black",
            fontSize: "20px",
          }}
        >
          No vote yet, come back later.
        </div>
      )}
      {cardsForVote?.length &&
        cardsForVote.map(({ month, cards }) => {
          const cardVoteLeaderIds = getCardVoteLeaderIds(cards);
          const votedCardId = getVotedCardId(moment.utc({ month }));
          const voteMonth = moment.utc().format("MM");
          const voteYear = moment.utc().year();

          return (
            <>
              <div className="vote-page__content text-page__content">
                  <div className="vote-header">
                      <div className="vote-header__date-legend">
                          You can vote {isYearVote ? '20' : '01'}.{voteMonth}.{voteYear}-{isYearVote ? '31' : '15'}.{voteMonth}.{voteYear}
                      </div>
                      <div className="vote-header__date">{isYearVote ? `${voteYear - 1}` : moment.utc({month}).format("MMMM YYYY")}</div>
                  </div>
                <div className={cn(["vote-body", votedCardId && "vote-body--voted"])}>
                  {cards.map((item, index) => {
                    const isCardVoteLeader = cardVoteLeaderIds.includes(item.id);
                    return (
                      <VoteCard
                        key={`${item.id}_${index}`}
                        card={item}
                        setVotedCard={handleVote}
                        votedCardId={votedCardId}
                        isCardVoteLeader={isCardVoteLeader}
                        votingCard={votingCard}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
});
