import React, { useCallback, useEffect, useMemo, useState } from "react";

import { SortFieldValue } from "pages/GalleryPage/components/GalleryFilters/constants/sort.constants";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_SIZE } from "shared/constants/paginated.constants";
import { NftCardModel, VoteListModel } from "shared/http/ApiModels";
import { getCardsForVote, getGallery, getWinnersList } from "shared/http/http.utils";

import { GalleryCenterContext } from "./GalleryCenterContext";
import { GalleryFilters, VoteListItemModel } from "./models";
import moment from "moment";
import {
  YEAR_END_VOTE_DATE,
  YEAR_START_VOTE_DATE,
  YEAR_VOTE_MONTH
} from "../../../pages/MainPage/components/VoteBlock/VoteBlock.constants";

export const GalleryCenterProvider: React.FC = React.memo(({ children }) => {
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<GalleryFilters>({ sort: SortFieldValue.NEWEST });
  const [data, setData] = useState<NftCardModel[]>([]);
  const [cardsForVote, setCardsForVote] = useState<VoteListItemModel[]>([]);
  const [winners, setWinners] = useState<VoteListModel[]>([]);
  const [page, setPage] = useState(DEFAULT_PAGE_NUMBER);

  const fetchGallery = useCallback(async () => {
    try {
      const gallery = await getGallery();
      setData(gallery);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchWinners = useCallback(async () => {
    try {
      const result = await getWinnersList();
      setWinners(result ?? []);
    } catch (e) {
      console.log(e);
    }
  }, []);

  const fetchCardForVote = useCallback(async () => {
    try {
      const cards = await getCardsForVote();

      const preparedCards = (cards ?? [])
        .reduce((acc, curr) => {
          const currentCardMonth = moment(curr.date, "DD.MM.YYYY").month();
          const targetAccData = acc.find(({ month }) => month === currentCardMonth);
          if (targetAccData) {
            return [
              ...acc.filter(({ month }) => month !== currentCardMonth),
              { ...targetAccData, cards: [...targetAccData.cards, curr] },
            ];
          }
          return [...acc, { month: currentCardMonth, cards: [curr] }];
        }, [] as VoteListItemModel[])
        .sort((a, b) => a.month - b.month);

      setCardsForVote(preparedCards);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    fetchGallery().catch();
    fetchCardForVote().catch();
    fetchWinners().catch();
  }, [fetchGallery, fetchCardForVote, fetchWinners]);

  const changeFilters = useCallback((filters: Partial<GalleryFilters>) => {
    setFilters((prev) => ({ ...prev, ...filters }));
    setPage(DEFAULT_PAGE_NUMBER);
  }, []);

  const changePagination = useCallback((page: number) => {
    setPage(page);
  }, []);

  const filteredData = useMemo(() => {
    let copiedData = [...data];
    if (filters.searchString) {
      copiedData = copiedData.filter(
        ({ author, name }) =>
          author.toLowerCase().includes(filters.searchString!.toLowerCase()) ||
          name.toLowerCase().includes(filters.searchString!.toLowerCase())
      );
    }
    if (filters.date?.year) {
      copiedData = copiedData.filter(({ date }) => {
        const cardYear = moment.utc(date, "DD.MM.YYYY").year();
        return cardYear === filters.date?.year;
      });
    }
    if (filters.date?.month || filters.date?.month === 0) {
      copiedData = copiedData.filter(({ date }) => {
        const cardMonth = moment.utc(date, "DD.MM.YYYY").month();
        return cardMonth === filters.date?.month;
      });
    }
    return copiedData.sort((a, b) => {
      if (filters.sort === SortFieldValue.NEWEST) {
        return moment.utc(b.date, "DD.MM.YYYY").diff(moment.utc(a.date, "DD.MM.YYYY"));
      }
      if (filters.sort === SortFieldValue.OLDEST) {
        return moment.utc(a.date, "DD.MM.YYYY").diff(moment.utc(b.date, "DD.MM.YYYY"));
      }
      if (filters.sort === SortFieldValue.ABC) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      }

      if (a.name.toLowerCase() > b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() < b.name.toLowerCase()) return 1;
      return 0;
    });
  }, [data, filters]);

  const paginatedData = useMemo(() => {
    if (!filteredData.length) {
      return filteredData;
    }
    const start = (page - 1) * DEFAULT_PAGE_SIZE;
    const end = start + DEFAULT_PAGE_SIZE;
    return filteredData.slice(start, end);
  }, [page, filteredData]);

  const isYearVote = useMemo(() => {
    const today = moment.utc();
    const month = today.month();
    const day = today.date();

    return month === YEAR_VOTE_MONTH && day >= YEAR_START_VOTE_DATE && day <= YEAR_END_VOTE_DATE;
  }, []);

  const preparedCardsForVote = useMemo(() => {
      if (!cardsForVote.length) {
          return [];
      }
    if (isYearVote) {
      return [cardsForVote.reduce((acc, curr) => {
          return { cards: [...acc.cards, curr.cards[0]]};
      } , { cards: [] } as any)]
    }
    return cardsForVote;
  }, [isYearVote, cardsForVote])

  return (
    <GalleryCenterContext.Provider
      value={[
        { loading, filters, data: filteredData, allData: data, paginatedData, page, cardsForVote: preparedCardsForVote, winnersList: winners },
        { changeFilters, changePagination, fetchCardForVote },
      ]}
    >
      {children}
    </GalleryCenterContext.Provider>
  );
});
