import {MonthWinner, VoteListModel, WinnersList} from "shared/http/ApiModels";

const currentYear = new Date().getFullYear();

const MAX_MONTH_WINNERS_LENGTH = 12;

const currentYearMonthsWinnersMap = (list: MonthWinner[]): MonthWinner[] => {
    if (list.length === MAX_MONTH_WINNERS_LENGTH) {
        return list;
    }

    return new Array(MAX_MONTH_WINNERS_LENGTH)
        .fill(null)
        .map((_, index) => list[index] ?? {});
}

export const winnersListMap = (list: VoteListModel[]): WinnersList[] => {
    return [...list].reverse().map((item) => {
        const monthWinners = currentYear === item.year ? currentYearMonthsWinnersMap(item.monthWinners) : item.monthWinners;

        return {
            ...item,
            monthWinners,
            choiceWinner: item.juryChoice.winner,
            choiceSilver: item.juryChoice.silver,
            choiceBronze: item.juryChoice.bronze,
        }
    })
}
