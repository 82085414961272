import React from "react";

import { TextPageLayout, TextPageHeader, TextPageBody } from "shared/components/TextPageLayout";

export const PrivacyPolicyPage: React.FC = () => {
  return (
    <TextPageLayout>
      <TextPageHeader>Privacy policy</TextPageHeader>
      <TextPageBody>
        <div className="text-content">
          <p>Effective Date: April 04, 2022</p>
          <p>
            Thank you for visiting the Gatia Labs Weird NFT Award site at{" "}
            <a href="https://weirdnftaward.com/" target="_blank" rel="noreferrer">
              https://weirdnftaward.com/
            </a>
            .
          </p>
          <p>
            This Privacy Policy covers any of your Personal Information obtained by Gatia Labs. If we make any material
            changes, we will post the new policy here, with a new effective date.
          </p>
          <p>
            To the extent permitted by applicable law, your use of this Site signifies your understanding and acceptance
            of the terms of this Privacy Policy, which together with the Terms of Service, govern your use of the Site.
            By using Gatia Labs website and other names under which we do business (collectively, “Site”), you consent
            to use of information that is collected or submitted as described in this Privacy Policy.
          </p>
          <p>
            “Personal information” is data about an individual who can be identified from that data, or from that data
            and other information to which we have access. Personal Information is usually your name in combination with
            another identifier, such as date of birth or address.
          </p>
          <div className="text-content__subtitle">What Personal Information Do We Collect?</div>
          
          <p>
            We may ask you for your name and email address, and telephone number information when you participate in the
            Gatia Labs platform, create, complete or update your Gatia Labs account.
          </p>
          <p>
            We may seek Personal Information regarding your age to verify that you are not a minor in accordance with
            our policy against doing business with children, set forth below.
          </p>
          <p>
            You are not required to provide the personal information we request, but if you decline, you may not
            participate in the Gatia Labs platform, and we may not be able to respond to queries you may have.
          </p>
          <div className="text-content__subtitle">How Do We Use Your Personal Information?</div>
          
          <p>
            To transact business with you in connection with the Gatia Labs platform and related transactions (e.g.,
            requests for information about Gatia Labs, NFT or other products or services) and otherwise serve you as a
            customer;
          </p>
          <p>
            To assess your interests, preferences and degree of satisfaction with the Gatia Labs platform and/or related
            products and services;
          </p>
          <p>
            To keep you posted on new or updated Gatia Labs offerings or related products, services and/or upcoming
            events;
          </p>
          <p>
            To improve the effectiveness of the Site, products, services, operations, mobile experience or marketing
            efforts;
          </p>
          <p>To verify your identity and eligibility for related products and services;</p>
          <p>
            To conduct auditing, data analysis, and research to improve Gatia Labs products, services, and customer
            communications;
          </p>
          <p>To allow our employees to service your customer needs;</p>
          <p>
            To prevent, deter and investigate violation of this Privacy Policy and our Terms and Conditions, or any
            fraud and other illegal activities;
          </p>
          <p>To send you important notices and changes to our business policies.</p>
          <div className="text-content__subtitle">With Whom Do We Share Your Personal Information?</div>
          
          <p>
            We share your Personal Information with third party service providers who facilitate Gatia Labs, manage and
            enhance our systems or customer data, provide customer service, assess your interest in our products and
            services, and conduct customer research or satisfaction surveys. These third parties may include
            infrastructure and IT service providers, including cloud service providers, marketing, advertising and
            communications agencies, external auditors and advisors, cookie analytics providers, online advertisers and
            analytics services. These companies have agreed to protect your information as a condition of receiving or
            processing it.
          </p>
          <p>
            We may disclose your Personal Information if we determine that disclosure is reasonably necessary to enforce
            our Terms of Service, this Privacy Policy or to otherwise protect our operations or users.
          </p>
          <p>
            We may release your Personal Information to others (including law enforcement) if we believe such release is
            reasonably necessary to comply with the law or legal process; enforce or apply the terms of applicable terms
            of service or use; detect, prevent, or otherwise address fraud, security or technical issues; or otherwise
            protect the rights, property or safety of others.
          </p>
          <p>
            We may sell, transfer and/or disclose your Personal Information as part of a business divestiture, sale,
            merger, or acquisition of all or a part of our business. Upon such change, that company will possess the
            Personal Data collected by us and will assume the rights and obligations regarding your Personal Information
            as described in this Privacy Policy. In the event of an insolvency, bankruptcy, or receivership, Personal
            Information may also be transferred as a business asset.
          </p>
          <p>
            We may share your Personal Information with our dealers, suppliers and partners, who may provide you with
            SMS, email and other communications and offers for products and services, including offers based on your
            interests, personal and business characteristics, stated preferences and location. We may also share your
            personal information with our corporate affiliates to provide or offer services to you.
          </p>
          <div className="text-content__subtitle">How Do We Protect Your Personal Information?</div>
          
          <p>
            We maintain a variety of security measures, including physical, electronic and procedural safeguards. No
            system can be completely secure, so we do not promise, and you should not expect, that your Personal
            Information, searches, or other communications will always remain secure.
          </p>
          <div className="text-content__subtitle">Access to Personal Information</div>
          
          <p>
            You can ask us for the Personal Information we possess, so that you can determine if it is inaccurate or if
            you want it deleted, provided we are not required to retain it by law or for legitimate business purposes.
          </p>
          <p>
            We may decline to process requests that are frivolous/vexatious, jeopardize the privacy of others, are
            extremely impractical, or for which access is not otherwise required by local law. Access, correction, or
            deletion requests can be made to{" "}
            <a href="mailto:support@gatialabs.com" target="_blank" rel="noreferrer">
              support@gatialabs.com
            </a>
            .
          </p>
          <div className="text-content__subtitle">What Other Information Do We Collect?</div>
          
          <p>
            We automatically gather information when you browse or view our Site. This log data may include your browser
            type (e.g., Chrome, Safari, Firefox or Internet Explorer), your domain, the Internet Protocol (IP) address
            assigned to your computer (or the unique device ID of your mobile device, or other unique identifier), the
            date and time of your visits, the pages on our site you visit and other information related to your visit.
          </p>
          <p>
            We collect data that does not, on its own, permit direct association with any specific individual. We may
            collect, use, transfer, and disclose such non-personal information for any purpose.
          </p>
          <p>
            If we combine non-personal information with your Personal Information, we will maintain the combined
            information as Personal Information for as long as it remains combined.
          </p>
          <p>
            We may collect aggregated information regarding participant activities on our Site, mobile application, and
            from our other products and services we may offer.
          </p>
          <p>We may collect details of how visitors to the Site use our services.</p>
          <p>
            You may give consent for our Site to capture and record certain precise geolocation or global positioning
            data from your device in order to tailor information to your location. We may link such location data to
            other information that you provide to us or that may be accessed in connection with your use of our Site. We
            will separately notify you and obtain your opt-in consent before collection or use of such geolocation or
            global positioning data.
          </p>
          <div className="text-content__subtitle">How Do We Employ Cookies and Similar Technologies?</div>
          
          <p>
            We also collect information from Site visitors using “cookies.” Cookies are small text files stored on your
            device by our Site. They carry navigational information from your Site visits. We use cookies to transact
            with you, personalize your experience, analyze usage patterns, and otherwise improve our Site, products and
            services. The cookies we use do not store sensitive personal information, such as your address, your
            password, or your credit card data.
          </p>
          <p>Our cookies generally fall into one of four categories: Essential, Functional, Analytics and Target.</p>
          <p>
            Essential Cookies are necessary to provide you with products and services available through our Website. If
            you refuse them, it will impact your ability to access some of the Website’s functions and features.
          </p>
          <p>
            Performance Cookies help us enhance the performance and functionality of our Website, products and service,
            but are not necessary for your use. Still, certain functions or features may become unavailable if you
            refuse them.
          </p>
          <p>
            Analytics Cookies collect information that is used to help us understand the use of our Website, products
            and services, the effectiveness of our marketing campaigns, or to help us customize our Website to enhance
            your experience.
          </p>
          <p>
            Target Cookies record your visit to our Website, the pages you have visited and the links you have followed,
            so we can recognize you as a prior visitor and track your activity on our Website and others you visit.
            These Cookies remain on your device for us to use during a next visit to our Website. In contrast to these
            “persistent” cookies, some cookies (“session cookies”) are deleted when your browsing session has ended.
          </p>
          <p>
            We also allow third parties to use cookies on our Site to collect information about your online activities
            over time and across different websites you visit. This information is used to provide advertising tailored
            to your interests on websites you visit, also known as interest based advertising, and to analyze the
            effectiveness of such advertising.
          </p>
          <p>
            You may restrict, block or delete the cookies from this Site at any time by changing the configuration of
            your browser. You can find out how to do this, and find more information on cookies, at
            https://www.allaboutcookies.org/. In addition, while each browser has different settings and configurations,
            cookies settings can typically be adjusted in the "Preferences" or "Tools" menu. Your browser’s "Help" menu
            may provide additional information.
          </p>
          <p>
            There are “do not track” or similar browser choices available to internet users with respect to online web
            activity, but given the present lack of consensus of what constitutes an informed election, appropriate
            trigger or breadth of scope of such choice, our Site will not operate any differently when a “do not track”
            signal is received.
          </p>
          <div className="text-content__subtitle">Social Networking Sites</div>
          
          <p>
            You may be able to post content to Social Networking Sites (such as Facebook, Instagram or Twitter) on which
            we maintain a presence. If you choose to do this, we will provide information to the Social Networking Sites
            in accordance with your elections. You acknowledge and agree that you are solely responsible for your use of
            those websites and that it is your responsibility to review the terms of use and privacy policies. We are
            not responsible for the availability, accuracy, content, products or services of such Social Network Sites.
          </p>
          <div className="text-content__subtitle">Third Party Links</div>
          
          <p>
            Our Site may have links or be linked to other sites we do not own, control or maintain. We are not
            responsible for and make no representations or warranties about the privacy practices of those sites. We
            recommend that you check the privacy policies of other sites and contact the operator if you have concerns
            or questions. Nonetheless, we seek to protect the integrity of our Site and welcome any feedback about these
            third party sites.
          </p>
          <div className="text-content__subtitle">Children</div>
          
          <p>
            If you are under the age of majority where you live, you must obtain consent from your parent or guardian to
            use the Gatia Labs Products. Notwithstanding the foregoing, you may not use the services if you are under
            the age of majority where you live if you use PayPal in association with your account. If we learn that we
            have collected personal information from anyone under the age of 13, we will endeavor to delete such
            information as soon as reasonably possible.
          </p>
          <div className="text-content__subtitle">Opting Out of Communications</div>
          
          <p>
            You may opt-out of receiving marketing-related emails by clicking the "unsubscribe" link at the bottom of
            any email you receive from us or by changing the communication preferences on your online account or
            profile. If you no longer want to receive promotional text messages from us, please follow the cancellation
            instructions contained in the last text message. If you are having difficulty unsubscribing from our
            marketing communications using the above methods, please contact us at{" "}
            <a href="mailto:support@gatialabs.com" target="_blank" rel="noreferrer">
              support@gatialabs.com
            </a>
            .
          </p>
          <p>
            If you opt-out from receiving marketing emails or text messages, we may still need to send you
            communications about your orders, customer service inquiries, promotions participation and other matters.
          </p>
          <p>
            If you agreed to receive future marketing communications directly from a third party through our Site, you
            will need to contact that party to opt-out of such communications. This process may be outlined on that
            party’s Privacy Policy.
          </p>
          <div className="text-content__subtitle">Privacy Rights of California Residents</div>
          
          <p>
            If you are a California resident, you may have certain rights with respect to your personal information,
            including the following:
          </p>
          <p>
            the right to know, by way of our Privacy Policy and any specific inquiries you direct to us, the source of
            the personal information we have about you, what we use that information for, whether it is being disclosed
            or sold, and to whom it is being disclosed or sold;
          </p>
          <p>the right to “opt-out” of Gatia Labs’ sale of your personal information to any third parties;</p>
          <p>
            the right, with some exceptions, to have your personal information deleted from Gatia Labs’ possession or
            control;
          </p>
          <p>
            the right to receive equal service and pricing from Gatia Labs even if you exercise any of your privacy
            rights.
          </p>
          <p>
            California Civil Code Section 1798.83 permits California residents who use our Site to request certain
            information regarding our disclosure of personal information to third parties for their direct marketing
            purposes. To make such a request, or if you have any questions or concerns about your California privacy
            rights, our Privacy Policy or our use of your personal information, please send an e‐mail to
            <a href="mailto:support@gatialabs.com" target="_blank" rel="noreferrer">
              support@gatialabs.com
            </a>
            .
          </p>
          <p>
            For all California residents, any such inquiries shall be responded to within forty-five (45) days and at no
            cost to you. We must verify your identity with respect to such inquiries. Depending on the nature of the
            Personal Information at issue, we may require additional measures or information from you as part of that
            verification.
          </p>
          <p>
            For California residents under age 18 who have publicly posted content or information, you may request and
            obtain removal of such content or information pursuant to California Business and Professions Code Section
            22581, provided you are registered user of any Site where this Policy is posted. To make such a request,
            please send an email with a detailed description of the specific content or information to
            support@gatialabs.com. Please be aware that such a request does not ensure complete or comprehensive removal
            of the content or information you have posted and that the law may not require or allow removal in all
            instances, even if requested.
          </p>
          <p>
            If you are a California resident, you may have the right to request and receive certain information about a
            company’s disclosure of your personal information to third parties for their own direct marketing use, and
            your choices with respect to such disclosures.
          </p>
          <p>
            California Civil Code Section 1798.83 permits users of the Site that are California residents to request
            certain information regarding our disclosure of personal information to third parties for their direct
            marketing purposes. To make such a request, please send an email to{" "}
            <a href="mailto:support@gatialabs.com" target="_blank" rel="noreferrer">
              support@gatialabs.com
            </a>
            .
          </p>
          <p>
            For individuals in the European Economic Area, please see below for information about your rights to your
            personal data.
          </p>
          <div className="text-content__subtitle">Changes to our Privacy Statement</div>
          
          <p>
            We may update this Privacy Statement from time to time, so please check the effective date at the top of
            this page as the date of last revision. Changes will become immediately effective upon posting on our Site
            unless otherwise noted. Your use of the Site following these changes indicates your consent to the practices
            described in the revised Privacy Statement.
          </p>
          <div className="text-content__subtitle">Contacting Us</div>
          
          <p>
            If there are any questions regarding this privacy policy you may contact us using the information at{" "}
            <a href="mailto:support@gatialabs.com" target="_blank" rel="noreferrer">
              support@gatialabs.com
            </a>
            .
          </p>
          <div className="text-content__subtitle">Privacy Rights of European Union Residents</div>
          
          <p>
            Gatia Labs has a legitimate interest in learning more about user preferences to offer products and services
            that better meet your needs and desires. In our Privacy Policy, we explain how we use your online activity
            and personal data for such purposes and the controls you have over those uses. If you are a resident of the
            European Union or the European Economic Area or the United Kingdom, you are afforded certain rights
            regarding your personal data. Specifically, EU, EEA and UK residents have the following rights:
          </p>
          <p>
            <div>
              To be informed about what happens with your Personal Data (information relating to an identified or
              identifiable natural person), including how it is collected from you, how it is used as well as the right
              to access that Personal Data, to modify it when it is incomplete or incorrect, and the right to withdraw
              consent for its use;
            </div>
            <div>To access Personal Data held by organizations about an individual;</div>
            <div>
              To rectification allowing individuals to correct inaccurate information or complete incomplete
              information;
            </div>
            <div>
              To erasure (or the right to be forgotten) allowing, with some exceptions, an individual to have their
              Personal Data deleted upon request;
            </div>
            <div>To restriction and withdraw consent for data processing;</div>
            <div>
              To data portability allowing individuals to ask for the transfer of their Personal Data or to obtain and
              use their Personal Data for their own purposes;
            </div>
            <div>To object, with some exceptions, to the processing of an individual’s Personal Data;</div>
            <div>
              To prevent automated decision making or profiling and to require that those decisions be made by natural
              persons.
            </div>
          </p>
        </div>
      </TextPageBody>
    </TextPageLayout>
  );
};
