import { ReactNode } from "react";

export enum SortFieldValue {
  NEWEST = "Newest",
  OLDEST = "Oldest",
  ABC = "abc",
  CBA = "cba",
}

export interface SelectOptionsType<T = any> {
  value: T;
  label: ReactNode;
  key: string;
}

export const sortOptions: SelectOptionsType<SortFieldValue>[] = [
  { value: SortFieldValue.NEWEST, label: "Newest", key: SortFieldValue.NEWEST },
  { value: SortFieldValue.OLDEST, label: "Oldest", key: SortFieldValue.OLDEST },
  { value: SortFieldValue.ABC, label: "A→Z", key: SortFieldValue.ABC },
  { value: SortFieldValue.CBA, label: "Z→A", key: SortFieldValue.CBA },
];
