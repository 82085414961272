import React from "react";

import { SortFieldValue } from "pages/GalleryPage/components/GalleryFilters/constants/sort.constants";
import { DEFAULT_PAGE_NUMBER } from "shared/constants/paginated.constants";

import { GalleryCenterActions, GalleryCenterState } from "./models";

export const GalleryCenterContext = React.createContext<[GalleryCenterState, GalleryCenterActions]>([
  {
    loading: false,
    filters: {
      sort: SortFieldValue.NEWEST,
    },
    data: [],
    allData: [],
    paginatedData: [],
    cardsForVote: [],
    page: DEFAULT_PAGE_NUMBER,
    winnersList: [],
  },
  {
    changeFilters: () => {},
    changePagination: () => {},
    fetchCardForVote: () => {
      throw new Error();
    },
  },
]);
