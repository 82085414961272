import React from "react";

import { Link } from "react-router-dom";
import Icon from "@ant-design/icons";

import { ReactComponent as Twitter } from "assets/icons/twitter.svg";
import { ReactComponent as Mastodon } from "assets/icons/mastodon.svg";
import { ReactComponent as Medium } from "assets/icons/medium.svg";
import {ReactComponent as Discord} from "assets/icons/discord.svg";
import { ReactComponent as Telegram } from "assets/icons/telegram.svg";
import { RouteUrls } from "router/routing.constants";

import "./Footer.scss";

export const Footer: React.FC = React.memo(() => {
  return (
    <div className="footer">
      <a href="https://www.gatialabs.com/" rel="noreferrer" target="_blank">
        <div className="ooyoot-logo" />
      </a>
      <div className="footer__title">
        <span style={{ color: "#FF4802" }}>weird</span>
        <span style={{ color: "#FF00FF" }}>nft</span>
        <span style={{ color: "#47CFFE" }}>award</span>
      </div>
      <div className="footer__subtitle">First International Annual NFT Art Award</div>
      <div className="footer__links">
        <span>
          <Link
            to={(location: Location) => {
              setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
              return { ...location, pathname: RouteUrls.COOKIE_POLICY_URL };
            }}
          >
            Cookie policy
          </Link>
        </span>
        <span>
          <Link
            to={(location: Location) => {
              setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
              return { ...location, pathname: RouteUrls.PRIVACY_POLICY_URL };
            }}
          >
            Privacy policy
          </Link>
        </span>
        <span>
          <Link
            to={(location: Location) => {
              setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
              return { ...location, pathname: RouteUrls.AWARD_RULES_URL };
            }}
          >
            Award Rules
          </Link>
        </span>
      </div>
      <div className="footer__social">
        <a href="https://twitter.com/GatiaLabs" target="_blank" rel="noreferrer">
          <Icon className="twitter-icon" component={Twitter} />
        </a>
        <a href="https://t.me/weirdnft" target="_blank" rel="noreferrer">
          <Icon className="telegram-icon" component={Telegram} />
        </a>
        <a rel="me" href="https://mastodon.social/@gatialabs" target="_blank">
          <Icon className="mastodon-icon" component={Mastodon} />
        </a>
        {/*<a href="https://gatialabs.medium.com/" rel="noreferrer" target="_blank">*/}
        {/*  <Icon className="medium-icon" component={Medium} />*/}
        {/*</a>*/}
        <a href="https://discord.com/invite/MgV32NTpAH/" rel="noreferrer" target="_blank">
          <Icon className="discord-icon" component={Discord} />
        </a>


      </div>
      <div className="footer__info">
        <span>WEIRD NFT AWARD</span>
        <span>®/TM & © 2021-2023 GATIA LABS ALL RIGHTS RESERVED.</span>
      </div>
    </div>
  );
});
