import React from "react";

import { TextPageLayout, TextPageHeader, TextPageBody } from "shared/components/TextPageLayout";

export const FaqPage: React.FC = () => {
  return (
    <TextPageLayout>
      <TextPageHeader>FAQ</TextPageHeader>
      <TextPageBody>
        <div className="text-content">
          <div className="text-content__subtitle">What is the Weird NFT Award?</div>
          
          <p>
            The Weird NFT Award (hereinafter - the Award) is an international independent award in the field of crypto
            art, established in 2021
          </p>

          <div className="text-content__subtitle">What are the objectives of the Award?</div>
          
          <p>
            Recognize, reward and promote talented NFT creators from all over the world. To highlight and reward the
            strangest, most unusual and interesting works in the field of non-fungible tokens on any blockchains.
          </p>

          <div className="text-content__subtitle">Who is the organizer of the Award?</div>
          
          <p>
            International development team{" "}
            <a href="https://www.gatialabs.com/" target="_blank" rel="noreferrer">
              Gatia Labs.
            </a>
          </p>

          <div className="text-content__subtitle">What awards from the non-digital world could an analogy be made?</div>
          
          <p>
            Cannes Lions International Festival of Creativity, The Night of the AD Eaters, Diagram Prize for Oddest
            Title of the Year. Marcel Duchamp Prize.
          </p>

          <div className="text-content__subtitle">
            Who is the Award aimed at? Are they people of art, business, IT or for someone else?
          </div>
          
          <p>
            The award is aimed at a wide range of participants, because in order to do something strange and unusual and
            present it in the form of an NFT, it is not necessary to be an IT specialist or graduate from an art
            academy.
          </p>

          <div className="text-content__subtitle">Are there any criteria for selecting nominees?</div>
          
          <p>
            There are no clear selection criteria and there is no clear definition of the word “Weird”. We do not
            pretend to be academic and strict criteria. If NFT causes thoughts like "What is it all about?" or "Wow, I
            didn't think it could be expressed THAT way", then this NFT is right for us. The main thing is that the eye
            clings to something, and some kind of associative series arises, some thoughts and emotions. Well, then the
            nominees are put up for a general vote and the final choice is made by the community.
          </p>

          <div className="text-content__subtitle">What is the structure of the award?</div>
          
          <p>
            The structure of the award in 2022: every month the organizers select five nominees for the short list and
            once a quarter a general vote is held for each of the three months. The winner of the month receives a prize
            NFT in the "Weird NFT of the Month" category and is eligible to participate in the annual voting in the
            "Weird NFT of the Year" category. In parallel with the voting, the winners in the “Jury's Choice” nomination
            are determined.
          </p>

          <div className="text-content__subtitle">Who can become a nominee or participate in the selection?</div>
          
          <p>Absolutely any person of any gender, age, nationality. Voting is also open to everyone.</p>

          <div className="text-content__subtitle">What opportunities does the Award give for the NFT creators?</div>
          
          <p>
            The award is intended to popularize NFT as a new media, to promote authors, regardless of their fame, the
            cost of their NFTs, their experience and profession. Unfortunately, now in the crypto world many
            publications, the press and influencers evaluate works based mainly on the value of NFTs in the secondary
            market, and new little-known authors will not be paid any attention. For us, it doesn’t matter in principle
            how much NFT costs, whether the author is a well-known person in a certain field, and whether he already has
            some experience. We post permanent links to the author's work on our social networks and on the Award
            website (which contributes to the price increase for this NFT), give participants prize NFTs with secondary
            market value, which can also be used in our Play-to-Earn game{" "}
            <a href="https://neuroborncats.com" target="_blank" rel="noreferrer">
              Neuroborn Cats
            </a>{" "}
            as quite powerful and rare artifacts. The plans for the development of our Metaverse also include the
            creation of the NFT Museum, in which all nominees and winners of the Award will be exhibited in a separate
            room.
          </p>

          <div className="text-content__subtitle">
            Are there categories (different nominations) or is there one overall rating?
          </div>
          
          <p>
            Now the Award has three nominations - Weird NFT of the Month, Weird NFT of the Year and Jury's Choice. We
            plan to increase the number of nominations in the future.
          </p>

          <div className="text-content__subtitle">
            In your opinion, what characteristics can help to reach the top of the award?
          </div>
          
          <p>
            An unusual view of the world, non-trivial ideas of the authors, courage, expressed in combinations of
            seemingly incompatible things.
          </p>

          <div className="text-content__subtitle">Are there any additional fees to participate in the award?</div>
          
          <p>No, there are no fees to participate in the Award, it was, is and will be free to participate.</p>

          <div className="text-content__subtitle">
            Is sponsorship or other commercial and information cooperation possible?
          </div>
          
          <p>
            We are interested in attracting media partners and commercial sponsors of the Award. Sponsorship
            demonstrates the commitment of the partner to the principles promoted by the Award, allowing you to
            associatively consolidate the company's brand as keeping up to date with the most advanced technologies of
            the crypto industry. Of course, for a partner there is also a marketing attraction, reaching a new audience,
            but first of all, this is the formation of positive associations for the partner’s brand as a patron of the
            new modern crypto art. For all cooperation questions, you can write to{" "}
            <a href="https://fancymail@weirdnftaward.com" target="_blank" rel="noreferrer">
              fancymail@weirdnftaward.com
            </a>
            .
          </p>
        </div>
      </TextPageBody>
    </TextPageLayout>
  );
};
