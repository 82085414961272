import { RouteProps } from "react-router-dom";

import { MainPage } from "pages/MainPage";
import { AboutTheAwardPage } from "pages/AboutTheAwardPage";
import { GalleryPage } from "pages/GalleryPage";
import { AboutNft } from "pages/AboutNft";
import { VotePage } from "pages/VotePage";
import { AwardRulesPage } from "pages/AwardRulesPage";
import { PrivacyPolicyPage } from "pages/PrivacyPolicyPage";
import { CookiePolicyPage } from "pages/CookiePolicyPage";
import { WinnersPage } from "pages/WinnersPage";
import { FaqPage } from "pages/FaqPage";

export enum RouteUrls {
  ROOT_URL = "/",
  ABOUT_THE_AWARD_URL = "/about-the-award",
  GALLERY_URL = "/gallery",
  VOTE_URL = "/vote",
  WINNERS_URL = "/winners",
  FAQ_URL = "/faq",
  AUTH_URL = "/auth",
  ABOUT_NFT_URL = "/about-nft",
  AWARD_RULES_URL = "/award-rules",
  PRIVACY_POLICY_URL = "/privacy-policy",
  COOKIE_POLICY_URL = "/cookie-policy",
}

export const routes: RouteProps[] = [
  {
    component: MainPage,
    path: RouteUrls.ROOT_URL,
    exact: true,
  },
  {
    component: AboutTheAwardPage,
    path: RouteUrls.ABOUT_THE_AWARD_URL,
    exact: true,
  },
  {
    component: GalleryPage,
    path: RouteUrls.GALLERY_URL,
    exact: true,
  },
  {
    component: AboutNft,
    path: `${RouteUrls.ABOUT_NFT_URL}/:id`,
    exact: false,
  },
  {
    component: VotePage,
    path: RouteUrls.VOTE_URL,
    exact: true,
  },
  {
    component: AwardRulesPage,
    path: RouteUrls.AWARD_RULES_URL,
    exact: true,
  },
  {
    component: PrivacyPolicyPage,
    path: RouteUrls.PRIVACY_POLICY_URL,
    exact: true,
  },
  {
    component: CookiePolicyPage,
    path: RouteUrls.COOKIE_POLICY_URL,
    exact: true,
  },
  {
    component: WinnersPage,
    path: RouteUrls.WINNERS_URL,
    exact: true,
  },
  {
    component: FaqPage,
    path: RouteUrls.FAQ_URL,
    exact: true,
  },
];
