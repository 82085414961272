import React, {useCallback} from "react";

import {useHistory} from "react-router-dom";

import {googleDiskImageToWeb} from "shared/utils/googleDiskImageToWeb";

import moment from "moment";
import {useGalleryCenter} from "core/context/GalleryCenter";
import WinnersBlock1 from "assets/images/winners/winner-content-1-min.jpg";
import WinnersBlock2 from "assets/images/winners/winner-content-2-min.jpg";

import {RouteUrls} from "router/routing.constants";

import {winnersListMap} from "./mappers";
import {YearAwardeePlace} from "./types";
import {YearAwardee} from "./YearAwardee";
import {MonthAwardee} from "./MonthAwardee";

import "./WinnersPage.scss";

export const WinnersPage: React.VFC = () => {
    const [{winnersList}] = useGalleryCenter();
    const history = useHistory();
    const currentYear = new Date().getFullYear();

    const winners = winnersListMap(winnersList);

    const handleCardClick = useCallback(
        (cardId) => {
            history.push(`${RouteUrls.ABOUT_NFT_URL}/${cardId}`);
            window.scroll({top: 0, behavior: "smooth"});
        },
        [history]
    );

    return (
        <div className="winners-page text-page">
            <div className="winners-page__header text-page__header">
                <div className="text-page__title text-border">winners</div>
            </div>
            <div className="winners-page__content text-page__content">
                {winners.map((list, index) => {
                    return (
                        <div className="winners-page__content text-page__content" key={list.year}>
                            {list.year === currentYear &&
                                <div
                                    className="winners-block"
                                    style={{backgroundImage: `url(${index % 2 === 0 ? WinnersBlock1 : WinnersBlock2})`}}
                                >
                                    <div className="winners-block__title">{list.year}</div>

                                    <div className="year-winners">
                                        <div className="year-winners__winners">
                                            <div className="year-winners__title">
                                                Weird nft of the year
                                            </div>
                                            <div className="year-winners__content">
                                                <YearAwardee
                                                    item={list?.yearWinner}
                                                    onClick={handleCardClick}
                                                    place={YearAwardeePlace.Gold}
                                                />

                                                <div className="year-winners__group">
                                                    <YearAwardee
                                                        item={list?.yearSilver}
                                                        onClick={handleCardClick}
                                                        place={YearAwardeePlace.Silver}
                                                    />
                                                    <YearAwardee
                                                        item={list?.yearBronze}
                                                        onClick={handleCardClick}
                                                        place={YearAwardeePlace.Bronze}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="year-winners__winners">
                                            <div className="year-winners__title">
                                                jury’s choice
                                            </div>
                                            <div className="year-winners__content">
                                                <YearAwardee
                                                    item={list?.choiceWinner}
                                                    onClick={handleCardClick}
                                                    place={YearAwardeePlace.Gold}
                                                />
                                                <div className="year-winners__group">
                                                    <YearAwardee
                                                        item={list?.choiceSilver}
                                                        onClick={handleCardClick}
                                                        place={YearAwardeePlace.Silver}
                                                    />
                                                    <YearAwardee
                                                        item={list?.choiceBronze}
                                                        onClick={handleCardClick}
                                                        place={YearAwardeePlace.Bronze}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="month-winners">
                                        <div className="month-winners__title">
                                            Weird nft of the month
                                        </div>
                                        <div className="month-winners__content">
                                            {list.monthWinners.map((item, index) => {
                                                return <MonthAwardee
                                                    key={item?.winner?.id ?? index}
                                                    item={item.winner}
                                                    month={item?.month ?? index}
                                                    onClick={handleCardClick}
                                                />
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }

                            {list.year !== currentYear &&
                                <div
                                    className="winners-block"
                                    style={{backgroundImage: `url(${index % 2 === 0 ? WinnersBlock1 : WinnersBlock2})`}}
                                >
                                    <div className="winners-block__title">{list.year}</div>

                                    <div
                                        className={Object.keys(list.juryChoice).length ? "year-winners" : "year-winners" + " " + "single"}>
                                        <div className={"year-winners__winners"}>
                                            <div className="year-winners__title">
                                                Weird nft of the year
                                            </div>
                                            <div className="year-winners__content">
                                                <YearAwardee
                                                    item={list.yearWinner}
                                                    onClick={handleCardClick}
                                                    place={YearAwardeePlace.Gold}
                                                />

                                                <div className="year-winners__group">
                                                    <YearAwardee
                                                        item={list.yearSilver}
                                                        onClick={handleCardClick}
                                                        place={YearAwardeePlace.Silver}
                                                    />

                                                    <YearAwardee
                                                        item={list.yearBronze}
                                                        onClick={handleCardClick}
                                                        place={YearAwardeePlace.Bronze}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {(list.choiceWinner && list.choiceSilver && list.choiceBronze) &&
                                            <div className="year-winners__winners">
                                                <div className="year-winners__title">
                                                    jury’s choice
                                                </div>
                                                <div className="year-winners__content">
                                                    <YearAwardee
                                                        item={list.choiceWinner}
                                                        onClick={handleCardClick}
                                                        place={YearAwardeePlace.Gold}
                                                    />

                                                    <div className="year-winners__group">
                                                        <YearAwardee
                                                            item={list.choiceSilver}
                                                            onClick={handleCardClick}
                                                            place={YearAwardeePlace.Silver}
                                                        />

                                                        <YearAwardee
                                                            item={list.choiceBronze}
                                                            onClick={handleCardClick}
                                                            place={YearAwardeePlace.Bronze}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="month-winners">
                                        <div
                                            className={list.monthWinners.length < 6 ? 'month-winners__title-center' : 'month-winners__title'}>
                                            Weird nft of the month
                                        </div>
                                        <div
                                            className={list.monthWinners.length < 6 ? 'month-winners__content-min' : 'month-winners__content'}>
                                            {list?.monthWinners?.map((card, index) => {
                                                return (
                                                    <div
                                                        key={card.winner?.id}
                                                        className={list.monthWinners.length < 6 ? 'month-winners__card-min' : 'month-winners__card'}>
                                                        <div className="month-winners__card-image"
                                                             onClick={() => handleCardClick(card.winner.id)}
                                                        >
                                                            <img src={googleDiskImageToWeb(card.winner?.avatarLink)}
                                                                 alt="image-month"/>
                                                        </div>
                                                        <div className="month-winners__card-title">
                                                            {moment((card?.month ?? index) + 1, "M").format("MMMM")}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    )
                })}
            </div>
        </div>
    );
};
