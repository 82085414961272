import { initAxios } from "./index";
import { NftCardModel, ProfileModel, VoteListModel } from "./ApiModels";

export const baseURL = process.env.REACT_APP_API_BASE_URL || "";

const http = initAxios({ baseURL });

interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    "Content-Type": contentType,
  };
  return configs;
}

function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  return http
    .request(configs)
    .then((res) => {
      resolve(res.data);
    })
    .catch((err) => {
      reject(err);
    });
}

export const vote = async (args: { profileId: string; cardId: string }): Promise<ProfileModel> => {
  return new Promise((resolve, reject) => {
    const url = "/vote";
    const configs: IRequestConfig = getConfigs("post", "application/json", url, {});
    configs.data = args;
    axios(configs, resolve, reject);
  });
};

export const getProfile = async (args: { profileId: string }): Promise<ProfileModel> => {
  return new Promise((resolve, reject) => {
    const url = "/profile";
    const configs: IRequestConfig = getConfigs("post", "application/json", url, {});
    configs.data = args;
    axios(configs, resolve, reject);
  });
};

export const getGallery = async (): Promise<NftCardModel[]> => {
  return new Promise((resolve, reject) => {
    const url = "cards/gallery";
    const configs: IRequestConfig = getConfigs("get", "application/json", url, {});
    axios(configs, resolve, reject);
  });
};

export const getCardsForVote = async (): Promise<NftCardModel[]> => {
  return new Promise((resolve, reject) => {
    const url = "cards/vote-cards";
    const configs: IRequestConfig = getConfigs("get", "application/json", url, {});
    axios(configs, resolve, reject);
  });
};

export const getWinnersList = async (): Promise<VoteListModel[]> => {
  return new Promise((resolve, reject) => {
    const url = "vote/vote-list";
    const configs: IRequestConfig = getConfigs("get", "application/json", url, {});
    axios(configs, resolve, reject);
  });
};
