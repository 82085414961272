import React from "react";

import { Col, Row } from "antd";

import { AboutAwardContent } from "../../content/AboutAward.content";

import "./AboutAwardBlock.scss";

export const AboutAwardBlock: React.FC = React.memo(() => {
  return (
    <div className="about-award-block">
      <div className="about-award-block__title">{AboutAwardContent.title}</div>
      <Row gutter={[60, 30]} justify="center" style={{ paddingTop: "50px", height: "100%", display: "flex" }}>
        <Col span={8} className="image-block">
          <img className="about-award-block__image" src={AboutAwardContent.image} alt="image" />
        </Col>
        <Col span={16}>
          <div className="about-award-block__text">{AboutAwardContent.content}</div>
        </Col>
      </Row>
    </div>
  );
});
