import React, { useMemo, useCallback, useState, useEffect} from "react";

import { Button, Form, Select } from "antd";

import { useGalleryCenter } from "core/context/GalleryCenter";
import { AntSelect } from "shared/components/AntSelect";

import { CURRENT_YEAR, MAX_YEAR_SELECT, MIN_YEAR_SELECT, CURRENT_MONTH } from "../../constants/dates.constants";
import { DateForm as DateFormModel } from "../../models";

import "./DateForm.scss";
import {NftCardModel} from "../../../../../../shared/http/ApiModels";

const { Option } = Select;

export const DateForm: React.FC = React.memo(() => {
  const [galleryCenterState, galleryCenterActions] = useGalleryCenter();
  const [dateForm] = Form.useForm<DateFormModel>();
  const [isTouched, setIsTouched] = useState(false);
  const [{ allData }] = useGalleryCenter();

  const yearsList = useMemo(() => {
    const result = [];
    for (let i = MAX_YEAR_SELECT - MIN_YEAR_SELECT; i >= 0; i -= 1) {
      result.push(CURRENT_YEAR - i);
    }
    return result.reverse();
  }, []);

  let monthsArr = [
    {
      month: 'January',
      number: 0
    },
    {
      month: 'February',
      number: 1
    },
    {
      month: 'March',
      number: 2
    },
    {
      month: 'April',
      number: 3
    },
    {
      month: 'May',
      number: 4
    },
    {
      month: 'June',
      number: 5
    },
    {
      month: "July",
      number: 6
    },
    {
      month: 'August',
      number: 7
    },
    {
      month: 'September',
      number: 8
    },
    {
      month: 'October',
      number: 9
    },
    {
      month: 'November',
      number: 10
    },
    {
      month: 'December',
      number: 11
    }
  ]
  const [months, setMonths] = useState(monthsArr)

  const getYears = (data:NftCardModel[]) => {
    const yearsArray:any[] = [];

    data.forEach(nft => {
      if(!yearsArray.includes(nft.year)) {
        yearsArray.push(nft.year)
      }
    })
    yearsArray.sort((a,b) => {
      return b-a
    })

    setYears([...yearsArray])
  }

  const getMonths = (data:NftCardModel[]) => {
    const monthArray:any[] = [];

    data.forEach(nft => {
      const month = monthsArr.find(month => month.month === nft.month);

      if(!monthArray.includes(month)) {
        monthArray.push(month)
      }
    })
    monthArray.sort((a:{number: number}, b:{number: number}) => {
      return a.number - b.number
    })

    setMonths([...monthArray])
  }

  const [years, setYears] = useState(yearsList);

  const handleDateFormSubmit = useCallback(
    (values: DateFormModel) => {
      galleryCenterActions.changeFilters({ date: values });
    },
    [galleryCenterActions]
  );

  const resetFields = () => {
    dateForm.setFieldsValue({'month': undefined});
    dateForm.setFieldsValue({'year': undefined});
    galleryCenterActions.changeFilters({ date: {"year": undefined, "month": undefined}});
    setIsTouched(false);
    getYears(allData);
    getMonths(allData);
  }

  const updateYear = (month:any) => {
    const nftThisMonth:any[] = [];
    // @ts-ignore
    const monthName = monthsArr.find(item => item.number === month).month;
    allData.forEach(nft => {
      if(nft.month === monthName) {
        if(!nftThisMonth.includes(nft.month)) {
          nftThisMonth.push(nft)
        }
      }
    })
    getYears(nftThisMonth);
  }

  const updateMonth = (year:any) => {
    const nftThisYear:any[] = [];
    allData.forEach(nft => {
      if(nft.year === year) {
        nftThisYear.push(nft)
      }
    })

    getMonths(nftThisYear);
  }


  return (

    <Form
      form={dateForm}
      name="dateForm"
      className="date-form"
      initialValues={galleryCenterState?.filters?.date}
      onFinish={handleDateFormSubmit}
      onFieldsChange={() => {
        setIsTouched(true);
      }}
    >
      <Form.Item name="year" noStyle>
        <AntSelect placeholder="Select year" allowClear={false} onSelect={updateMonth}>
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </AntSelect>
      </Form.Item>
      <Form.Item name="month" noStyle>
        <AntSelect placeholder="Select month" allowClear={false} onSelect={updateYear}>
          {months.map((month) => (
            <Option key={month.month} value={month.number} title={month.month}>
              {month.month}
            </Option>
          ))}
        </AntSelect>
      </Form.Item>
      <Form.Item noStyle>
        <Button htmlType="submit" className="date-form__submit">Confirm</Button>
      </Form.Item>
      <Button className={isTouched ? 'date-form__reset' : 'date-form__reset-disabled'} onClick={resetFields} disabled={!isTouched}>
        Reset
      </Button>
    </Form>
  );
});
