export const CURRENT_YEAR = new Date().getFullYear();
export const CURRENT_MONTH = new Date();
export const MAX_YEAR_SELECT = CURRENT_YEAR;
export const MIN_YEAR_SELECT = 2021;

export const MONTHS = [
  {month:"January", number: 0},
  {month: "February", number: 1},
  {month: "March", number: 2},
  {month: "April", number: 3},
  {month: "May", number: 4},
  {month: "June", number: 5},
  {month: "July", number: 6},
  {month: "August", number: 7},
  {month: "September", number: 8},
  {month: "October", number: 9},
  {month: "November", number: 10},
  {month: "December", number: 11},
];
