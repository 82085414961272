/**
 Даты квартального голосования:
 - первый квартал за январь февраль март - 1-10 апреля;
 - второй квартал за апрель май июнь - 1-10 июля;
 - третий квартал за июль август сентябрь - 1-10 октября;
 - четвертый квартал за октябрь ноябрь декабрь - 1-10 января.
 */
export const QUARTER_VOTE_MONTHS = [0, 3, 6, 9];
export const QUARTER_START_VOTE_DATE = 1;
export const QUARTER_END_VOTE_DATE = 10;

/**
 Даты годового голосования: с 20 по 30 января.
 */
export const YEAR_VOTE_MONTH = 0;
export const YEAR_START_VOTE_DATE = 20;
export const YEAR_END_VOTE_DATE = 31;

const FIRST_QUARTER_VOTE_MONTHS = [0, 1, 2];
const SECOND_QUARTER_VOTE_MONTHS = [3, 4, 5];
const THIRD_QUARTER_VOTE_MONTHS = [6, 7, 8];
const FOURTH_QUARTER_VOTE_MONTHS = [9, 10, 11];

export const getQuarterVoteMonths = new Map([
  [3, FIRST_QUARTER_VOTE_MONTHS],
  [6, SECOND_QUARTER_VOTE_MONTHS],
  [9, THIRD_QUARTER_VOTE_MONTHS],
  [0, FOURTH_QUARTER_VOTE_MONTHS],
]);
