import React, {useMemo} from "react";

import {Button, Row} from "antd";
import {useHistory} from "react-router-dom";
import moment from "moment";
import {RouteUrls} from "router/routing.constants";

import "./VoteNow.scss";
import {
    getQuarterVoteMonths,
    YEAR_END_VOTE_DATE,
    YEAR_START_VOTE_DATE,
    YEAR_VOTE_MONTH
} from "../../VoteBlock.constants";

interface Props {
    voteDate: string;
}

export const VoteNow: React.VFC<Props> = React.memo(({voteDate}) => {
    const history = useHistory();

    const isYearVote = useMemo(() => {
        const today = moment.utc();
        const month = today.month();
        const day = today.date();

        return month === YEAR_VOTE_MONTH && day >= YEAR_START_VOTE_DATE && day <= YEAR_END_VOTE_DATE;
    }, []);

    const voteMonths = useMemo(() => {
        const date = moment(voteDate, "DD.MM.YYYY");
        const months = getQuarterVoteMonths.get(date.month()) ?? [];
        return months.map((item) => moment.utc({month: item}).format("MMMM")).join(", ");
    }, [voteDate]);


    return (
        <div className="vote-now">
            <Row>
                <div className="vote-now__title text-border">{`vote for the most weird nft of the ${isYearVote ? "year!" : "month"}`}</div>
            </Row>
            <Row>
                <div
                    className="vote-now__month text-border">{isYearVote ? moment.utc().year() - 1 : voteMonths}</div>
            </Row>
            <Row style={{paddingTop: "15px"}}>
                <Button className="vote-now__button" onClick={() => history.push(RouteUrls.VOTE_URL)}>
                    Vote now
                </Button>
            </Row>
        </div>
    );
});
