import React from "react";

import { Col, Row } from "antd";

import { ImageOrderEnum, PrizeBlockContent } from "../../content/PrizeBlock.content";

import "./PrizesBlock.scss";

export const PrizesBlock: React.FC = React.memo(() => {
  return (
    <div className="prizes-block">
      <div className="prizes-block__top" />
      <div className="prizes-block__content">
        <div className="prizes-block__title">{PrizeBlockContent.title}</div>
        {PrizeBlockContent.content.map((item, index) => {
          return (
            <Row
              gutter={[80, 30]}
              style={{ padding: "50px 30px 30px 30px", height: "100%", display: "flex", justifyContent: "center" }}
            >
              <Col span={10} className="block-image" order={item.imageOrder === ImageOrderEnum.Left ? 0 : 1}>
                <img className="prizes-block__image" src={item.image} alt="Prize image" />
              </Col>
              <Col span={14}>
                <div className="prizes-block__text">
                  {item.title && <div className="text-title">{item.title}</div>}
                  {item.content}
                </div>
              </Col>
            </Row>
          );
        })}
      </div>
    </div>
  );
});
