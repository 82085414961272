import React, { useCallback } from "react";

import { useHistory } from "react-router-dom";

import { googleDiskImageToWeb } from "shared/utils/googleDiskImageToWeb";
import { NftCardModel } from "shared/http/ApiModels";
import { RouteUrls } from "router/routing.constants";

import "./GalleryItemCard.scss";

interface GalleryItemCardProps {
  card: NftCardModel;
}

export const GalleryItemCard: React.FC<GalleryItemCardProps> = React.memo(({ card }) => {
  const history = useHistory();

  const handleCardClick = useCallback(() => {
    history.push(`${RouteUrls.ABOUT_NFT_URL}/${card.id}`);
    window.scroll({ top: 0, behavior: "smooth" });
  }, [history, card]);

  return (
    <div className="gallery-item-card">
      <div className="gallery-item-card__avatar" onClick={handleCardClick}>
        {card?.avatarLink && (
          <img className="gallery-item-card__avatar_image" src={googleDiskImageToWeb(card.avatarLink)} alt={"avatar"} />
        )}
      </div>
      <div className="gallery-item-card__nft-name">{card?.name ?? ""}</div>
      <div className="gallery-item-card__artist-name">{card?.author ?? ""}</div>
    </div>
  );
});
