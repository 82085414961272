import React from "react";

import AwardFirstImg from "assets/images/main/award_first.jpg";
import BadgeImg from "assets/images/main/badge.jpg";
import NovemberImg from "assets/images/main/june_2022_s.jpg";
import JuryAwardImg from "assets/images/main/jury_award_1_box_s-min.jpg";

export enum ImageOrderEnum {
  Left = "left",
  right = "right",
}

interface TextBlockModel {
  title?: string;
  content: React.ReactNode;
  image: string;
  imageOrder: ImageOrderEnum;
}

export interface PrizeBlockContentModel {
  title: string;
  content: TextBlockModel[];
}

export const PrizeBlockContent: PrizeBlockContentModel = {
  title: "Prizes",
  content: [
    {
      title: "weird nft of the month",
      content: (
        <>
          <br />
          <p>
            The winners of the monthly voting receive a prize NFT “Weird NFT of the Month” from the Award
            Administration, indicating the month and year in which the vote was held. Prize NFT can be transferred by
            the winner to another account, but cannot be burned. The prize NFT is created on the WAX blockchain and sent
            to the WAX wallet of the author of the winning NFT.
          </p>
          <p>
            Prize NFT is a wooden plaque of A5 format, on which a metal sheet is fixed. This sheet shows the logo of the
            Award, the name of the Award, and the month and year in which the vote was held.
          </p>
          <p>
            Prize NFT can be used in the game staking system in the{" "}
            <a href="https://www.neuroborncats.com" target="_blank" rel="noreferrer">
              Neuroborn Cats
            </a>{" "}
            Play-and-Earn MMO game. The “Weird NFT of the Month” NFT provides significantly more in-game bonuses than
            most other NFTs available in the game staking system.
          </p>
        </>
      ),
      imageOrder: ImageOrderEnum.Left,
      image: NovemberImg,
    },
    {
      title: "weird nft of the year",
      content: (
        <>
          <br />
          <p>
            The winner of the annual vote receives a prize NFT “Weird NFT of the Year” of the first degree from the
            Award Administration, indicating the year in which the vote was held. Prize NFTcan be transferred by the
            winner to another account, but cannot be burned. The prize NFT is created on the WAX blockchain and sent to
            the WAX wallet of the author of the winning NFT.
          </p>
          <p>
            Nominees who took the second and third places in the annual voting receive the prize NFT “Weird NFT of the
            Year” of the second and third degree from the Award Administration, respectively, indicating the year in
            which the vote was held. Prize NFTcan be transferred by the winner to another account, but cannot be burned.
            The prize NFT is created on the WAX blockchain and sent to the WAX wallet of the author of the winning NFT.
          </p>
          <p>
            Prize NFT is a three-dimensional logo of the award, made of gold-plated (first place), silver-plated (second
            place) or bronze-plated (third place) metal .The logo is located on a glossy black pedestal. The pedestal
            indicates the year in which the Award was held and the final place (winner, second or third).
          </p>
          <p>
            Prize NFT can be used in the game staking system in the{" "}
            <a href="https://www.neuroborncats.com" target="_blank" rel="noreferrer">
              Neuroborn Cats
            </a>{" "}
            Play-and-Earn MMO game. The “Weird NFT of the Year” NFT provides much more in-game bonuses than most other
            NFTs available in the game staking system.
          </p>
        </>
      ),
      imageOrder: ImageOrderEnum.right,
      image: AwardFirstImg,
    },
    {
      title: "Participant badge",
      content: (
        <>
          <br />
          <p>
            All Participants who are shortlisted for monthly voting receive a badge of the Award Participant from the
            Award Administration in the form of NFT, indicating the month and year in which the vote was held. NFT can
            be transferred by the winner to another account, but cannot be burned. NFT is created on the WAX blockchain
            and sent to the WAX wallet of the author of the winning NFT.
          </p>
          <p>
            The Participant badge is a badge with the award logo in the center, above it there are white and red ribbons
            with the words “Weird NFT Award” and “Participant”. The year in which the Award was held is located under
            the logo.
          </p>
          <p>
            Participant badge can be used in the game staking system in the{" "}
            <a href="https://www.neuroborncats.com" target="_blank" rel="noreferrer">
              Neuroborn Cats
            </a>{" "}
            Play-and-Earn MMO game. Participant badge NFT provides more in-game bonuses than most other NFTs available in
            the game staking system.
          </p>
        </>
      ),
      imageOrder: ImageOrderEnum.Left,
      image: BadgeImg,
    },
    {
      title: "Jury's Choice",
      content: (
        <>
          <br />
          <p>
            Jury selection of three NFTs from all nominees during the year. The award has three classes - first, second
            and third.
          </p>
          <p>
            Jury's Choice badge is a hexagonal badge with the emblem of the Prize in the center. The award nomination is
            written above the emblem on a blue ribbon. Below, surrounded by laurel branches, the class of the award is
            indicated. Below this is the year the award was presented.
          </p>
          <p>
            Prize NFT can be used in the game staking system in the{" "}
            <a href="https://www.neuroborncats.com" target="_blank" rel="noreferrer">
              Neuroborn Cats
            </a>{" "}
            Play-and-Earn MMO game. The “Jury's Choice” NFT provides much more in-game bonuses than most other NFTs
            available in the game staking system.
          </p>
        </>
      ),
      imageOrder: ImageOrderEnum.right,
      image: JuryAwardImg,
    },
  ],
};
