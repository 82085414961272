import React, { useEffect, useMemo, useState } from "react";

import moment, { Moment } from "moment";

import { TimerPad } from "./components/TimerPad";

import "./NextVoteTimer.scss";

interface NextVoteTimerProps {
  nextVoteDate: Moment;
}

export const NextVoteTimer: React.FC<NextVoteTimerProps> = React.memo(({ nextVoteDate }) => {
  const today = moment();

  const isAfterDate = useMemo(() => nextVoteDate.isAfter(today), [nextVoteDate, today]);

  const days = useMemo(() => {
    return moment(nextVoteDate).diff(today, "days");
  }, [nextVoteDate, today]);

  const hours = useMemo(() => {
    return moment(nextVoteDate).subtract(days, "days").diff(today, "hours");
  }, [nextVoteDate, today, days]);

  const minutes = useMemo(
    () => moment(nextVoteDate).subtract(days, "days").subtract(hours, "hours").diff(today, "minutes"),
    [nextVoteDate, today, days, hours]
  );

  const [tick, setTick] = useState<number>(0);

  useEffect(() => {
    let timer: any;
    if (isAfterDate) {
      timer = setTimeout(() => setTick((prev) => prev + 1), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isAfterDate, tick]);

  return (
    <div className="next-vote-timer">
      <TimerPad value={days} label="Days" />
      <TimerPad value={hours} label="Hours" />
      <TimerPad value={minutes} label="Minutes" />
    </div>
  );
});
