import React, { Suspense } from "react";

import { BackTop } from "antd";
import Icon from "@ant-design/icons";
import { Route, Redirect, Switch } from "react-router-dom";

import { ReactComponent as TopArrow } from "assets/icons/top-arrow-button.svg";
import { withGalleryCenter } from "core/context/GalleryCenter";
import { Header } from "core/components/Header";
import { Footer } from "core/components/Footer";
// import { Loader } from "shared/components/Loader";
import { ShadowButton } from "shared/components/ShadowButton";

import { routes, RouteUrls } from "./routing.constants";

export const Roulette: React.FC = withGalleryCenter(
  React.memo(() => {
    return (
      <>
        {/*// <div className="page-layout">*/}
        <Header />
        {/*<Suspense fallback={<Loader isLoading={true} />}>*/}
        <Switch>
          {routes.map((routeProps, index) => {
            return <Route {...routeProps} key={index} />;
          })}
          <Redirect to={RouteUrls.ROOT_URL} />
        </Switch>
        {/*</Suspense>*/}
        <BackTop visibilityHeight={200}>
          <ShadowButton>
            <Icon className="top-arrow" component={TopArrow} />
          </ShadowButton>
        </BackTop>
        <Footer />
        {/*// </div>*/}
      </>
    );
  })
);
