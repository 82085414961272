import React from "react";

import {
  // FacebookShareButton,
  // FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  VKShareButton,
  VKIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

import "./ShareBlock.scss";

interface ShareBlockProps {
  url: string;
  size?: string;
}

export const ShareBlock: React.FC<ShareBlockProps> = React.memo(({ url, size = "2.2" }) => {
  const sizeRem = `${size}rem`;
  return (
    <div className="share-block">
      {/*<FacebookShareButton url={url} openShareDialogOnClick={true}>*/}
      {/*  <FacebookIcon className="share-block__icon" round size={sizeRem} />*/}
      {/*</FacebookShareButton>*/}
      <TelegramShareButton url={url}>
        <TelegramIcon className="share-block__icon" round size={sizeRem} />
      </TelegramShareButton>
      <WhatsappShareButton url={url}>
        <WhatsappIcon className="share-block__icon" round size={sizeRem} />
      </WhatsappShareButton>
      <VKShareButton url={url}>
        <VKIcon className="share-block__icon" round size={sizeRem} />
      </VKShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon className="share-block__icon" round size={sizeRem} />
      </TwitterShareButton>
    </div>
  );
});
