import React, { ReactElement, useCallback } from "react";

import { Pagination as AntPagination, PaginationProps } from "antd";

import "./Pagination.scss";

export const Pagination: React.FC<PaginationProps & { toTop?: () => void }> = React.memo(
  ({ onChange, toTop, ...rest }) => {
    const itemRenderHandler = useCallback(
      (page: number, type: "page" | "prev" | "next" | "jump-prev" | "jump-next", el: ReactElement) => {
        if (type === "prev") {
          return (
            <button className="ant-pagination-item-link" type="button" disabled={page === 1}>
              Previous
            </button>
          );
        }

        if (type === "next") {
          return (
            <button className="ant-pagination-item-link" type="button">
              Next
            </button>
          );
        }

        return el;
      },
      []
    );

    const handleChange = useCallback(
      (pageNumber: number, pageSize?: number) => {
        onChange?.(pageNumber, pageSize);
        if (toTop) {
          toTop();
        } else {
          window.scroll({ top: 0, behavior: "smooth" });
        }
      },
      [onChange, toTop]
    );

    return <AntPagination {...rest} showSizeChanger={false} itemRender={itemRenderHandler} onChange={handleChange} />;
  }
);
