import React from "react";

import "./TimerPad.scss";

interface TimerPadProps {
  value: number;
  label: string;
}

export const TimerPad: React.FC<TimerPadProps> = React.memo(({ value, label }) => {
  return (
    <div className="timer-pad">
      <div className="timer-pad__value text-border">{value < 10 ? `0${value}` : `${value}`}</div>
      <div className="timer-pad__label text-border">{label}</div>
    </div>
  );
});
