import React from "react";

import { GalleryCenterProvider } from "./GalleryCenterProvider";

export function withGalleryCenter<T = any>(Component: React.FC<T>): React.FC<T> {
  return (props) => {
    return (
      <GalleryCenterProvider>
        <Component {...props} />
      </GalleryCenterProvider>
    );
  };
}
