import React from "react";

export const TextPageBody: React.FC = React.memo(({ children }) => {
  return (
    <div className="text-page-layout__content text-page__content">
      <div className="text-page-layout__body text-page__body">{children}</div>
      <div className="text-page-layout__footer-image" />
    </div>
  );
});
