import React, { useCallback, useMemo } from "react";

import { Button } from "antd";

import cn from "classnames";
import moment from "moment";
import { GalleryItemCard } from "pages/GalleryPage/components/GalleryItemCard";
import { NftCardModel } from "shared/http/ApiModels";
import { ShareBlock } from "shared/components/ShareBlock";

import "./VoteCard.scss";

interface VoteCardProps {
  card: NftCardModel;
  votedCardId?: string;
  setVotedCard: (cardId: NftCardModel) => Promise<void>;
  isCardVoteLeader: boolean;
  votingCard: NftCardModel | null;
}

export const VoteCard: React.FC<VoteCardProps> = React.memo(
  ({ card, votedCardId, setVotedCard, isCardVoteLeader, votingCard }) => {
    const handleVoteClick = useCallback(
      (e: React.ChangeEvent<any>) => {
        e.preventDefault();
        e.stopPropagation();

        if (votedCardId) {
          return;
        }
        setVotedCard(card);
      },
      [card, votedCardId, setVotedCard]
    );

    const isCurrentCardVoted = votedCardId === card.id;
    const isCurrentCardVotingNow = votingCard?.id === card.id;

    const isCurrentCardDateVotingNow = useMemo(() => {
      if (!votingCard) {
        return false;
      }
      const votingCardDate = moment(votingCard.date, "DD.MM.YYYY");
      const currentCardDate = moment(card.date, "DD.MM.YYYY");
      return votingCardDate.year() === currentCardDate.year() && votingCardDate.month() === currentCardDate.month();
    }, [card, votingCard]);

    return (
      <div className={["vote-card", isCurrentCardVoted && "voted"].filter(Boolean).join(" ")}>
        <GalleryItemCard card={card} />
        <div className="vote-card__button">
          <Button
            onClick={handleVoteClick}
            loading={isCurrentCardVotingNow}
            disabled={!!votedCardId || isCurrentCardDateVotingNow}
          >
            {isCurrentCardVoted ? "Your choice" : "Vote"}
          </Button>
          <div className={cn(["vote-card__vote-percent", isCardVoteLeader && "vote-card__vote-percent--leader"])}>
            {Math.round(card?.votePercents ?? 0)}%
          </div>
        </div>
        {isCurrentCardVoted && (
            <ShareBlock url={`${window.location.origin}/#about-nft/${card.id}`} size="1.2" />
        )}
      </div>
    );
  }
);
