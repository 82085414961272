import React from "react";

import { TextPageLayout, TextPageHeader, TextPageBody } from "shared/components/TextPageLayout";

import MonthWinnerImg from "assets/images/main/june_2022_s.jpg";
import YearWinnerImg from "assets/images/main/award_first.jpg";
import YearSilverImg from "assets/images/main/3d_award_second_2022_s-min.jpg";
import YearBronzeImg from "assets/images/main/3d_award_third_2022_s-min.jpg";
import BadgeImg from "assets/images/main/badge.jpg";
import JuryChoiceGold from "assets/images/main/jury_award_1_box_s-min.jpg";
import JuryChoiceSilver from "assets/images/main/jury_award_2_box_s-min.jpg";
import JuryChoiceBronze from "assets/images/main/jury_award_3_box_s-min.jpg";

import "./AwardRulesPage.scss";

export const AwardRulesPage: React.FC = () => {
  return (
    <TextPageLayout>
      <TextPageHeader>Award Rules</TextPageHeader>
      <TextPageBody>
        <div className="text-content award-rule-page">
          <div className="text-content__title">About the award</div>
          <div className="text-content__subtitle">1. General Provisions</div>
          <p>
            1.1. The “WEIRD NFT AWARD” (hereinafter the Award) is an international independent award established in
            2021.
          </p>

          <p>
            1.2. The Award is governed by these Rules, has an advertising nature (including focus in increasing the
            brand awareness of “Gatia Labs”), but is not based on risk. The award does not require payment of a
            participation fee.
          </p>

          <p>1.3 Participation in the Award is free.</p>
          <div className="text-content__subtitle">2. Objective of the Award</div>
          <p>
            Recognize, reward and promote talented NFT contributors from all over the world. To highlight and reward the
            weirdest, most unusual and interesting works in the field of non-fungible tokens on any blockchain.
          </p>
          <div className="text-content__subtitle">3. The Award nominations</div>
          <p>Weird NFT of the Month Nominated for the weirdest NFT of a given month.</p>

          <p>Weird NFT of the Year Nominated for the weirdest NFT of a given year.</p>

          <p>Jury's Choice Jury selection of three NFTs from all nominees during the year.</p>
          <div className="text-content__subtitle">4. Terms and definitions</div>
          <p>
            4.1 Website - the pages of the Award on a specialized Internet resource on which voting takes place. Located
            on the Internet at:{" "}
            <a href="https://weirdnftaward.com" target="_blank" rel="noreferrer">
              https://weirdnftaward.com
            </a>
          </p>

          <p>
            4.2 Voice - a point given by a user of the Site by voting (one point is equal to one "like") for one of the
            participants of the Award. The Site user can vote for one Participant in each nomination only once.
          </p>

          <p>
            4.3 Cheating votes - a mechanical increase in the number of votes cast for a nominee, fixed by a robotic
            vote counting system, by using automated computer programs, or by placing an order on exchanges to earn
            money on actions in social networks, and not as a result of the actions of real users of the site.
          </p>

          <p>
            4.4 Participant is the author of the NFT that was included in the application for participation in the
            Award. Participants cannot be employees of the Award Administration, members of their families, as well as
            employees of other legal entities or DAOs associated with the Award with work obligations, and members of
            their families.
          </p>

          <p>
            4.5 Nominees - Participants whose applications have been moderated and posted on the official website of the
            Award in the shortlist of nominees.
          </p>

          <p>4.6 Laureates - winners and prize-winners of the Award.</p>

          <p>
            4.7 Shortlist - a list of candidates for the Award Laureates in each nomination, formed by the
            Administration of the Award choosing the most worthy candidates from the number of applications submitted on
            the Award website.
          </p>
          <div className="text-content__subtitle">5. Government of the Award</div>
          <p>
            5.1 The Administration of the Award (Administration) is the Government of the Award. The Administration is
            formed to provide organizational and financial support for the preparation and conducting of the Award, its
            legal and information support.
          </p>

          <p>5.2 Powers implemented by the Award Administration:</p>

          <p>
            5.2.1 Implementation of organizational and technical work for the preparation and conducting of the Award.
          </p>

          <p>5.2.2 Record paperwork for the organization and presentation of the Award.</p>

          <p>5.2.3 Determination of the Rules of the Award.</p>

          <p>5.2.4 Organization of the procedure of handing the NFT Awards and other Awards to the Laureates.</p>

          <p>5.2.5 Information support of the Award.</p>

          <p>
            5.2.6 Notifying the public of these Rules, the deadlines for accepting works, selecting participants,
            announcing the Laureates and presenting awards; as well as information on the awarding procedure and other
            information about the Award.
          </p>
          <div className="text-content__subtitle">6. Prize awarding Procedure</div>
          <p>6.1 Announcement of the Award</p>

          <p>
            6.1.1 The Organizing Committee announces the start of the Award by placing an official announcement on the
            Award Website -{" "}
            <a href="https://weirdnftaward.com/" target="_blank" rel="noreferrer">
              https://weirdnftaward.com
            </a>{" "}
            in the public domain, as well as publishing on social networks (Telegram -{" "}
            <a href="https://t.me/weirdnft" target="_blank" rel="noreferrer">
              https://t.me/weirdnft
            </a>
            , Twitter -{" "}
            <a href="https://twitter.com/gatialabs" target="_blank" rel="noreferrer">
              https://twitter.com/gatialabs
            </a>
            , Mastodon -{" "}
            <a href="https://mastodon.social/@gatialabs" target="_blank" rel="noreferrer">
              https://mastodon.social/@gatialabs
            </a>
            ).
          </p>

          <p>6.1.2 The open announcement shall indicate:</p>

          <p>6.1.2.1 Award Nominations;</p>

          <p>6.1.2.2 Conditions for accepting applications.</p>

          <p>6.2 Nomination of the Award participants</p>

          <p>6.2.1 Nomination of participants for the title of Laureate of the Award can be carried out as follows:</p>

          <p>6.2.1.1 Self-nomination of the Participants;</p>

          <p>6.2.1.2 Nomination by the Award Administration;</p>

          <p>6.2.1.3 Nomination by third parties submitting an application in the prescribed form.</p>

          <p>
            6.2.2 If the Participant does not want to take part in the Award, he can refuse to participate by sending an
            email to the Award Administration at{" "}
            <a href="mailto:fancymail@weirdnftaward.com" target="_blank" rel="noreferrer">
              fancymail@weirdnftaward.com
            </a>
            .
          </p>

          <p>6.2.3 Participants can be individuals of any age residing in the territory of any state.</p>

          <p>
            6.2.4 The procedure of nominating for the title of Laureate of the Award includes filing an application for
            the Award of the Participants by registering on the Award Website{" "}
            <a href="https://weirdnftaward.com" target="_blank`" rel="noreferrer">
              https://weirdnftaward.com
            </a>{" "}
            and filling out a form on the website.
          </p>

          <p>
            6.2.5 The same NFT may be submitted for the Weird NFT of the Month nomination multiple times if this NFT has
            never been shortlisted for the nomination before.
          </p>

          <p>
            6.2.6 Commission of acts by a person complying with the requirements of clause 4.4 of the Rules in
            accordance with these Rules is recognized as the Participant's consent to participate in the Prize under the
            conditions stipulated by these Rules.
          </p>

          <p>
            6.2.7 Any user of the website{" "}
            <a href="https://weirdnftaward.com" target="_blank`" rel="noreferrer">
              https://weirdnftaward.com
            </a>{" "}
            can apply. Sending an application, the Participant confirms his consent to the storage, use and processing
            of personal data. Applications are accepted until 23:59 of the last announced day (server time). The Award
            administration reserves the right to extend the deadline for accepting applications.
          </p>

          <p>
            6.2.8 Applications submitted after the specified period, or sent in any other way, except filling out the
            application form on the website{" "}
            <a href="https://weirdnftaward.com" target="_blank`" rel="noreferrer">
              https://weirdnftaward.com
            </a>
            , will not be considered.
          </p>

          <p>6.3 Procedure for determining the Award Laureates</p>

          <p>In 2023, the Award Winners are determined as follows:</p>

          <p>
            6.3.1 Five Participants are selected by the Award Administration on a monthly basis for the shortlist of the
            Weird NFT of the Month nomination.
          </p>

          <p>
            6.3.2 The works of the Participants shortlisted for a certain month are published on the Award Website
            and/or in the Award social networks during that month.
          </p>

          <p>
            6.3.3 Once a quarter, from the first to the tenth day (inclusive) of the following month, a public
            Internet voting is opened on the Award Website{" "}
            <a href="https://weirdnftaward.com" target="_blank`" rel="noreferrer">
              https://weirdnftaward.com
            </a>{" "}
            for the Participants from the shortlist.
          </p>

          <p>
            6.3.4. Based on the voting results, the winner of each of the three months with the highest score shall be
            determined.
          </p>

          <p>6.3.5 The winner of each month is automatically shortlisted for the Weird NFT of the Year nomination.</p>

          <p>
            6.3.6 From January 20 to January 30 (inclusive), 2024, public Internet voting will open on the Award
            Website https://weirdnftaward.com for Shortlisted Participants for the Weird NFT of the Year nomination.
          </p>

          <p>
            6.3.7. Based on the results of voting, the winner with the highest number of points is determined, as well
            as the winners who took the second and third places.
          </p>

          <p>
            6.3.8 The Award Administration officially announces the Award Laureates, including the winners in the Jury's
            Choice nomination.
          </p>
          <div className="text-content__subtitle">
            7. Distinguishing feature for Participants and Laureates of the Award, the form of the Award
          </div>

          <p>
            7.1 The award has its own symbols and is a non-monetary commemorative award and a nominee certificate issued
            to Participants in the form of non-fungible tokens (NFT). In 2023, prize NFTs can be created on the WAX and Polygon blockchains.
            Official WAX collection link (on AtomicHub marketplace):
            <a href="https://wax.atomichub.io/explorer/collection/weirdnftawar" target="_blank" rel="noreferrer">
              https://wax.atomichub.io/explorer/collection/weirdnftawar
            </a>
          </p>

          <p>7.2 Cash remuneration is not paid to Nominees and other Award Participants.</p>

          <p>
            7.3 The winners of the monthly voting receive a prize NFT “Weird NFT of the Month” from the Award
            Administration, indicating the month and year in which the vote was held. Prize NFTcan be transferred by the
            winner to another account, but cannot be burned. The prize NFT is created on the WAX or Polygon ​​blockchains 
            and sent to the WAX or Polygon ​​wallet of the author of the winning NFT.
          </p>

          <figure className="award-example">
            <img src={MonthWinnerImg} alt="Month Winner" />
            <figcaption>An example of the prize NFT in the nomination "Weird NFT of the Month"</figcaption>
          </figure>

          <p>
            7.4 The winner of the annual vote receives a prize NFT “Weird NFT of the Year” of the first degree from the
            Award Administration, indicating the year in which the vote was held. Prize NFTcan be transferred by the
            winner to another account, but cannot be burned. Prize NFT can be transferred by the winner to another account, but cannot be burned. 
            The prize NFT is created on the WAX or Polygon ​​blockchains and sent to the WAX or Polygon ​​wallet of the author of the winning NFT.
          </p>

          <figure className="award-example">
            <img src={YearWinnerImg} alt="Year Winner" />
            <figcaption>An example of the first degree prize NFT in the nomination “Weird NFT of the Year”</figcaption>
          </figure>

          <p>
            7.5 Nominees who took the second and third places in the annual voting receive the prize NFT “Weird NFT of
            the Year” of the second and third degree from the Award Administration, respectively, indicating the year in
            which the vote was held. Prize NFTcan be transferred by the winner to another account, but cannot be burned.
            Prize NFT can be transferred by the winner to another account, but cannot be burned. 
            The prize NFT is created on the WAX or Polygon ​​blockchains and sent to the WAX or Polygon ​​wallet of the author of the winning NFT.
          </p>

          <figure className="award-example">
            <img src={YearSilverImg} alt="Year Silver" />
            <figcaption>An example of the second degree prize NFT in the nomination “Weird NFT of the Year”</figcaption>
          </figure>


          <figure className="award-example">
            <img src={YearBronzeImg} alt="Year Bronze" />
            <figcaption>An example of the third degree prize NFT in the nomination “Weird NFT of the Year”</figcaption>
          </figure>

          <p>
            7.6 All Participants who are shortlisted for monthly voting receive a badge of the Award Participant from
            the Award Administration in the form of NFT, indicating the month and year in which the vote was held. NFT
            can be transferred by the winner to another account, but cannot be burned. 
            The Award Participant NFT is created on the WAX or Polygon ​​blockchains and sent to the WAX or Polygon ​​wallet of the author of the winning NFT.
          </p>

          <figure className="award-example">
            <img src={BadgeImg} alt="Badge" />
            <figcaption>Example of NFT badge of the Award Participant</figcaption>
          </figure>

          <p>
            7.7 The winner and nominees who took second and third places in the Jury's Choice nomination receive NFT
            “Weird NFT Award: Jury’s Choice” of the first place from the Administration the Award. Prize NFT can be
            burned by the winner, but cannot be transferred to another account. The prize NFT is created on the WAX or Polygon ​​blockchains 
            and sent to the WAX or Polygon ​​wallet of the author of the winning NFT.
          </p>

          <figure className="award-example">
            <img src={JuryChoiceGold} alt="Jury Choice First" />
            <figcaption>An example of the first class prize NFT in the nomination “Jury's Choice”</figcaption>
          </figure>


          <figure className="award-example">
            <img src={JuryChoiceSilver} alt="Jury Choice Second" />
            <figcaption>An example of the second class prize NFT in the nomination “Jury's Choice”</figcaption>
          </figure>


          <figure className="award-example">
            <img src={JuryChoiceBronze} alt="Jury Choice Third" />
            <figcaption>An example of the third class prize NFT in the nomination “Jury's Choice”</figcaption>
          </figure>

          <p>7.8 The Award Administration may establish and additionally award special prizes.</p>

          <p>
            7.9 The Award Administration has the right not to pay the Award Laureates the monetary equivalent of a
            commemorative award, nominee certificates, as well as special prizes (if available).
          </p>

          <p>
            7.10 The Award Administration may present physical equivalents of the Prize to the Award Laureates, if
            Laureates confirm the authorship rights to the NFT that won the Prize. The terms and conditions for
            confirming rights to authorship are determined by the Administration of the Award and may change at the
            discretion of the Administration of the Award.
          </p>

          <p>
            7.11 The physical equivalents of the Award to the Prize Laureates are sent worldwide free of charge, at the
            expense of the Award Administration.
          </p>

          <p>
            7.12 The Award Administration is not responsible for any tax liability that may arise as a result of
            receiving prizes from the Award and its sponsors.
          </p>
          <div className="text-content__subtitle">8. Funding</div>

          <p>
            8.1 Expenses for the preparation and holding the Award events are carried out by the Award Organizer at the
            expense of its own material resources.
          </p>
          <div className="text-content__subtitle">9. Partners</div>

          <p>9.1 The Award administration has the right to invite financial and information partners to the project.</p>

          <p>9.2 Information about all partners is posted on the Award Site https://weirdnftaward.com.</p>
          <div className="text-content__subtitle">
            10. Method of the contract conclusion between the Participant and the Award Administration
          </div>
          <p>
            10.1 The contract for participation in the Award between the Administration of the Award and the Participant
            is free of charge for the Participant, it is concluded by the Participant acceptance to the conditions
            contained in the text of these Rules in the following way: the conclusion of the specified contract is made
            by sending a public offer (proposal) by the Administration of the Award through publishing these Rules on
            the website of the Award and acceptance of the offer by a person who meets the requirements set out in
            clause 4.4. of the Rules by performing the actions specified in clause 6.2.4 of the Rules.
          </p>

          <p>
            10.2 The agreement between the Administration of the Award and the Participant is considered concluded at
            the moment of registration of the Participant on the Award website.
          </p>

          <p>
            10.3 The fact of registration of the Participant on the Award website implies that the Participant is
            familiar with these Rules and agrees with them; The Participant agrees to receive information about the
            Award from the Award Administration by email; The participant agrees to the inclusion of his email address
            in the mailing list of marketing and advertising proposals. The participant can at any time refuse to
            receive marketing and advertising offers to his mail.
          </p>
          <div className="text-content__subtitle">11. Additional terms</div>

          <p>
            11.1 The Award Administration has the right to exclude from the list of Participants and Laureates
            unilaterally and without giving reasons:
          </p>

          <p>
            11.1.1 Persons who do not meet the requirements provided for in clause 4.4 of the Rules, as well as persons
            who cheat votes in accordance with clause 4.3 of the Rules.
          </p>

          <p>
            11.1.2 Persons who act in a destructive manner, insult, threaten other Participants of the Award or
            representatives of the Administration of the Award.
          </p>

          <p>11.1.3 Persons who have violated other clauses of these Rules.</p>

          <p>11.1.4 The Award Administration has the right to issue a special NFT to an excluded person.</p>

          <p>
            11.2 In case of revealing the fact of cheating votes, the Administrator of the Award notifies the
            Participant of this by sending a warning letter to the email address specified during registration.
          </p>

          <p>
            11.3 If the number of twisted votes cast for the Participant on the Award website is more than 25% of the
            total number of votes, the Award Administration removes the Participant's profile from the website. If the
            Participant agrees to return to the site with zeroed votes, the Award Administration can once again create a
            participant's account on the Award website.
          </p>

          <p>
            11.4 If the number of twisted votes is less than 25% of the total number of votes cast for the Participant,
            the Award Administration has the right to subtract the twisted votes from the total number, leaving the
            votes of real users.
          </p>

          <p>
            11.5 If the Participant's profile was re-entered on the Award website, and the fact of vote cheating was
            revealed again, the Award Administration has the right to disable (delete) the Participant's account from
            the Award Website and from the number of nominees completely, with a ban on further participation in the
            Award.
          </p>

          <p>
            11.6 Participants excluded or not admitted to participation in the Award have the right to contact the Award
            Administration by e-mail fancymail@weirdnftaward.com for an explanation of the reasons, or in order to
            provide evidence that the exclusion and / or non-admission are unreasonable. The Award administration
            reserves the right not to enter into negotiations.
          </p>

          <p>
            11.7 If, for any reason, the Award cannot be conducted as planned, including reasons caused by infection
            with computer viruses, Internet problems, defects, manipulations, unauthorized interference, falsification
            caused by any reason uncontrolled by the Administration of the Award, which distorts or affects the
            performance, safety, honesty, integrity or proper conduct of the Award, the Award Administration may, in its
            sole discretion, cancel, terminate, modify or temporarily discontinue or invalidate any results of the
            Award.
          </p>

          <p>
            11.8 All Participants and Laureates of the Award shall independently pay all expenses incurred by them in
            connection with participation in the Award (including expenses related to Internet access).
          </p>

          <p>
            11.9 The Participant confirms that he has copyright and (or) exclusive rights to the results of intellectual
            activity and means of individualization used in NFTs, assessed in the framework of the Award nominations. In
            the event when claims or legal actions are taken against the Administration of the Award for violation of
            the copyright and (or) exclusive rights of third parties by the Participant, such Participant undertakes to
            settle such claims independently and at his own expense, as well as compensate all losses collected from the
            Administration of the Award at the expense of the copyright holder for the violation of rights.
          </p>
          <div className="text-content__subtitle">12. Personal data</div>
          <p>
            12.1 Participation in the Award confirms the fact that the Participant has provided consent to the
            processing of personal data to the Award Administration for the purpose of holding the Award. Processing the
            personal data of each Participant, the Award Administration has the right to perform the following actions:
            collection, recording, systematization, accumulation, storage, clarification (update, change), extraction,
            use, transfer (distribution, provision, access), depersonalization, blocking, deletion, destruction.
            Registering on the Award Site, the Participant may be additionally asked to consent to the processing of
            personal data by filling out a special application or in another way.
          </p>

          <p>
            12.2 The personal data of the Participant is processed until the end of the relationship within the
            framework of the Award.
          </p>

          <p>
            12.3 Consent to the processing of personal data can be revoked by the Participant-subject of personal data
            by sending a personal appeal to the Award Administration at fancymail@weirdnftaward.com.
          </p>

          <p>
            12.4 The processing of personal data may be carried out by the Award Administration personally, as well as
            by persons authorized by it.
          </p>

          <p>12.5 The processing of personal data can be carried out using automated data processing tools.</p>

          <p>
            12.6 The Administration of the Award may transfer the data of the Participants to partners and sponsors of
            the Award who help in organization of the event.
          </p>
          <div className="text-content__subtitle">
            13. Response of the Award Administration to external circumstances
          </div>

          <p>13.1 These Rules can be changed or supplemented by the Administration of the Award.</p>

          <p>
            13.2 The Award administration has the right to introduce new nominations, change the conditions and these
            Rules, or cancel or terminate the Award ahead of schedule if the Award website is affected by malicious
            computer programs (viruses), or equally if force majeure or other circumstances arise and make the Award
            impossible, impracticable , inappropriate, or for any other reason.
          </p>

          <p>
            13.3 Notification of changes in conditions or cancellation of the Award must be made in the same way in
            which the Award was announced.
          </p>
          <div className="text-content__subtitle">14. Awards organizer and feedback</div>

          <p>14.1 The Award is organized by International development team Gatia Labs.</p>

          <p>
            14.2 Email for communication with the Organizer:{" "}
            <a href="mailto:fancymail@weirdnftaward.com" target="_blank">
              fancymail@weirdnftaward.com.
            </a>
          </p>
        </div>
      </TextPageBody>
    </TextPageLayout>
  );
};
