import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import * as HttpStatus from "http-status-codes";

// Response inteceprors
function successResponseInterceptor(response: AxiosResponse): Promise<AxiosResponse> {
  if (response.request.responseType === "arraybuffer" && response.headers["content-disposition"]) {
    const contentDisposition = response.headers["content-disposition"];
    const fileName = decodeURIComponent(contentDisposition.toLowerCase().split("filename*=utf-8''")[1]).trim();
    return Promise.resolve({ ...response, data: { fileName, buffer: response.data } });
  }

  return Promise.resolve(response);
}

function failureResponseInterceptor(error: AxiosError): Promise<AxiosError> {
  if (error.response) {
    const { response } = error;

    switch (response.status) {
      case HttpStatus.UNAUTHORIZED:
        // store.dispatch(profileActions.logoutAction());
        break;

      case HttpStatus.FORBIDDEN:
        // createErrorNotification(`В доступе отказано (${HttpStatus.FORBIDDEN})`, stack);
        break;

      case HttpStatus.NOT_FOUND:
        // createErrorNotification(`Не найдено (${HttpStatus.NOT_FOUND})`, stack);
        break;

      case HttpStatus.INTERNAL_SERVER_ERROR:
      case HttpStatus.NOT_IMPLEMENTED:
      case HttpStatus.BAD_GATEWAY:
      case HttpStatus.SERVICE_UNAVAILABLE:
      case HttpStatus.GATEWAY_TIMEOUT:
        // createErrorNotification(`Неизвестная ошибка сервера (${response.status})`, stack);
        break;

      default:
        break;
    }
  }

  return Promise.reject(error.response);
}

// Request inteceptors
function successRequestInterceptor(request: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  return Promise.resolve(request);
}

function failureRequestInterceptor(error: AxiosError): Promise<AxiosError> {
  return Promise.reject(error);
}

export function initAxios({ baseURL, ...config }: AxiosRequestConfig = {}) {
  const httpConfig: AxiosRequestConfig = {
    baseURL: baseURL,
    responseType: "json",
    ...config,
  };

  const http: AxiosInstance = axios.create(httpConfig);
  http.interceptors.request.use(successRequestInterceptor, failureRequestInterceptor);
  http.interceptors.response.use(successResponseInterceptor, failureResponseInterceptor);
  return http;
}
