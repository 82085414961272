import React from "react";

import { AboutAwardBlock } from "./components/AboutAwardBlock";
import { PrizesBlock } from "./components/PrizesBlock";
import { VoteBlock } from "./components/VoteBlock";

import "./MainPage.scss";

export const MainPage: React.FC = () => {
  return (
    <div className="main-page">
      <VoteBlock />
      <AboutAwardBlock />
      <PrizesBlock />
    </div>
  );
};
