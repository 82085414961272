import React from "react";

import AwardLogoImg from "assets/images/main/award_logo.jpg";

export const AboutAwardContent: { image: string; content: React.ReactNode; title: string } = {
  image: AwardLogoImg,
  content: (
    <>
      <p>
        The “WEIRD NFT AWARD” is an international independent award established in 2021 by International development
        team{" "}
        <a href="https://www.gatialabs.com" target="_blank" rel="noreferrer">
          Gatia Labs.
        </a>
      </p>
      <p>
        Objectives of the Award: recognize, reward and promote talented NFT contributors from all over the world. To
        highlight and reward the weirdest, most unusual and interesting works in the field of non-fungible tokens on any
        blockchain.
      </p>
      <p>
        Participation in the Award is free. An author from any country, of any age, gender, political and religious
        views can participate in the Award. Unlike many crypto projects, we do not care about the cost of your NFTs, the
        number of subscribers on your social networks, or your professional experience. We are glad to all creative
        people, everyone who thinks outside the box, who is not afraid to go beyond the normal.
      </p>
      <div style={{ flexGrow: 1, display: "flex", alignItems: "flex-end", justifyContent: "center", width: "100%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            fontSize: "26px",
            lineHeight: "32px",
            fontFamily: "Joystix, sans-serif",
            color: "#FF00FF",
          }}
        >
          <div style={{ textAlign: "center" }}>DON’T KEEP YOUR CURIOSITY DOOR LOCKED.</div>
          <div style={{ textAlign: "center" }}>BE INQUISITIVE, BE BRAVE, BE REAL.</div>
          <div style={{ textAlign: "center" }}>THE POWER OF IMAGINATION MAKES US INFINITE.</div>
        </div>
      </div>
    </>
  ),
  title: "About the award",
};
