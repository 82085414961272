import React from "react";

import { Roulette, withRouter } from "router";

import { ProfileCenterProvider } from "core/context/ProfileCenter";

import "shared/styles/app.scss";

export const App: React.FC = withRouter(
  React.memo(() => {
    return (
      <ProfileCenterProvider>
        <div className="App">
          <Roulette />
        </div>
      </ProfileCenterProvider>
    );
  })
);
