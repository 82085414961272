import React, { useMemo } from "react";

import moment from "moment";

import { VoteNow } from "./components/VoteNow";
import { VoteStartTimer } from "./components/VoteStartTimer";

import "./VoteBlock.scss";
import {
  QUARTER_END_VOTE_DATE,
  QUARTER_START_VOTE_DATE,
  QUARTER_VOTE_MONTHS,
  YEAR_END_VOTE_DATE,
  YEAR_START_VOTE_DATE,
  YEAR_VOTE_MONTH,
} from "./VoteBlock.constants";

export const VoteBlock: React.FC = React.memo(() => {
  const voteDate = useMemo(() => {
    const today = moment.utc();
    const month = today.month();
    const day = today.date();

    if (month === YEAR_VOTE_MONTH && day > QUARTER_END_VOTE_DATE) {
      return moment.utc(`20.01.${today.year()}`, "DD.MM.YYYY");
    }

    if (QUARTER_VOTE_MONTHS.includes(month) && day <= QUARTER_END_VOTE_DATE) {
      return today;
    }

    const nextVoteMonth = QUARTER_VOTE_MONTHS.find((item) => item > month);
    return moment.utc({
      day: QUARTER_START_VOTE_DATE,
      month: nextVoteMonth ?? QUARTER_VOTE_MONTHS[0],
      year: nextVoteMonth ? today.year() : today.year() + 1,
    });
  }, []);

  const isVotePeriod = useMemo(() => {
    const today = moment.utc();
    const month = today.month();
    const day = today.date();

    /** Проверка на проведение годового голосования */
    if (month === YEAR_VOTE_MONTH && day > QUARTER_END_VOTE_DATE) {
      return day >= YEAR_START_VOTE_DATE && day <= YEAR_END_VOTE_DATE;
    }

    /** Проверка на проведение квартального голосования */
    if (QUARTER_VOTE_MONTHS.includes(month)) {
      return day >= QUARTER_START_VOTE_DATE && day <= QUARTER_END_VOTE_DATE;
    }

    return false;
  }, []);

  return (
    <div className="vote-block">
      {isVotePeriod ? (
        <VoteNow voteDate={voteDate.format("DD.MM.YYYY")} />
      ) : (
        <VoteStartTimer voteDate={voteDate} onSubmit={() => {}} />
      )}
    </div>
  );
});
