import React from "react";

import { Link, useHistory } from "react-router-dom";
import {useState} from "react";

// import { Button } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as Burger } from "assets/icons/burger.svg";
import { ReactComponent as Cross} from "assets/icons/cross.svg";

import { RouteUrls } from "router/routing.constants";

import "./Header.scss";

export const Header: React.FC = React.memo(() => {
  const history = useHistory();
  const [burgerShow, setBurgerShow] = useState(false);

  const toggleBurger = () => {
      setBurgerShow(!burgerShow)
  };
  return (
    <div className="header">
      <div
        className="header__title"
        onClick={() => {
          history.push("/");
        }}
      >
        <span style={{ color: "#FF4802" }}>weird</span>
        <span style={{ color: "#FF00FF" }}>nft</span>
        <span style={{ color: "#47CFFE" }}>award</span>
      </div>
      <div className="header__actions">
        <nav className="nav-links">
          <ul>
            <li>
              <Link
                to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.AWARD_RULES_URL };
                }}
              >
                Award Rules
              </Link>
            </li>
            <li>
              <Link
                to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.GALLERY_URL };
                }}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.VOTE_URL };
                }}
              >
                Vote
              </Link>
            </li>
            <li>
              <Link
                to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.WINNERS_URL };
                }}
              >
                Winners
              </Link>
            </li>
            <li>
              <Link
                to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.FAQ_URL };
                }}
              >
                FAQs
              </Link>
            </li>
            {/*<li>*/}
            {/*  <Button>Submit now</Button>*/}
            {/*</li>*/}
            {/*<li>Sign In</li>*/}
            {/*<li>Sign Up</li>*/}
          </ul>
        </nav>
      </div>
      <Icon className="header__btn" component={Burger} onClick={toggleBurger}/>

      <div className={"burger-menu" + " " + (burgerShow ? 'burger-menu__showed' : '')}>
          <Icon className="burger-menu__close" component={Cross} onClick={toggleBurger}/>
          <Link
              className="burger-menu__link"
              onClick={toggleBurger}
              to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.AWARD_RULES_URL };
              }}
          >
              Award Rules
          </Link>
          <Link
              className="burger-menu__link"
              onClick={toggleBurger}
              to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.GALLERY_URL };
              }}
          >
              Gallery
          </Link>
          <Link
              className="burger-menu__link"
              onClick={toggleBurger}
              to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.VOTE_URL };
              }}
          >
              Vote
          </Link>
          <Link
              className="burger-menu__link"
              onClick={toggleBurger}
              to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.WINNERS_URL };
              }}
          >
              Winners
          </Link>
          <Link
              className="burger-menu__link"
              onClick={toggleBurger}
              to={(location: Location) => {
                  setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
                  return { ...location, pathname: RouteUrls.FAQ_URL };
              }}
          >
              FAQs
          </Link>
      </div>

    </div>
  );
});
