import React from "react";

import { Row, Col } from "antd";

import { DateForm } from "./components/DateForm";
import { SortSearchBlock } from "./components/SortSearchBlock";

import "./GalleryFilters.scss";

export const GalleryFilters: React.FC = React.memo(() => {
  return (
    <Row justify="space-between">
      <Col span={8}>
        <DateForm />
      </Col>
      <Col span={12}>
        <SortSearchBlock />
      </Col>
    </Row>
  );
});
