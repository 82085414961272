export enum YearAwardeePlace {
    Gold = 'gold',
    Silver = 'silver',
    Bronze = 'bronze',
}

export enum Month {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December
}
