import React from "react";

import { Button, Row } from "antd";

import { Moment } from "moment";

import { NextVoteTimer } from "../NextVoteTimer";

import "./VoteStartTimer.scss";

interface VoteStartTimerProps {
  voteDate: Moment;
  onSubmit: () => void;
}

export const VoteStartTimer: React.FC<VoteStartTimerProps> = React.memo(({ voteDate, onSubmit }) => {
  return (
    <div className="vote-start-timer">
      <Row justify="center">
        <div className="vote-start-timer__title text-border">next vote will start in</div>
      </Row>
      <Row justify="center">
        <NextVoteTimer nextVoteDate={voteDate} />
      </Row>
      {/*<Row justify="center" style={{ paddingTop: "30px" }}>*/}
      {/*  <Button className="vote-start-timer__button" onClick={onSubmit}>*/}
      {/*    Submit NFT*/}
      {/*  </Button>*/}
      {/*</Row>*/}
    </div>
  );
});
