import React, { useMemo } from "react";

import { Button } from "antd";

import { useLocation } from "react-router-dom";

import { ShareBlock } from "shared/components/ShareBlock";
import { useGalleryCenter } from "core/context/GalleryCenter";
import { googleDiskImageToWeb } from "shared/utils/googleDiskImageToWeb";

import MonthImage from "assets/images/month-new.png";
import YearWinnerImage from "assets/images/year.jpg";
import YearSilverImage from "assets/images/year-silver.jpg";
import YearBronzeImage from "assets/images/year-bronze.jpg";

import "./AboutNft.scss";

export const AboutNft: React.FC = React.memo(() => {
  const location = useLocation();
  const [galleryCenterState] = useGalleryCenter();

  const currentCard = useMemo(() => {
    const pathnameArray = location.pathname.split("/");
    const currentCardId = pathnameArray[pathnameArray.length - 1];
    return galleryCenterState.data.find(({ id }) => id === currentCardId);
  }, [location.pathname, galleryCenterState.data]);

  return (
    <div className="about-nft text-page">
      <div className="about-nft__header text-page__header">
        <div className="text-page__title text-border">about nft</div>
      </div>
      <div className="about-nft__content text-page__content">
        <div className="nft-card">
          <div className="nft-card__gallery">
            <div className="nft-card__gallery__image-wrapper">
              <img
                className="nft-card__gallery__image"
                src={googleDiskImageToWeb(currentCard?.imageLink)}
                alt="image"
              />
            </div>
          </div>
          <div className="nft-card__info">
            <div className="nft-card__info_nft-name">{currentCard?.name ?? ""}</div>
            <div className="nft-card__info_artist-name">{currentCard?.author ?? ""}</div>
            {/*<div className="nft-card__info_interview-link">Read the interview</div>*/}
            <div className="nft-card__info_about-card">{currentCard?.description ?? ""}</div>
            <div className="nft-card__info_votes">
              {currentCard?.monthWinner && (
                <div className="vote-card">
                  <div className="vote-card__image-wrapper">
                    <div className="vote-card__image" style={{ backgroundImage: `url(${MonthImage})` }} />
                  </div>
                  <div className="vote-card__time">
                    <div className="vote-card__month">{currentCard.monthWinner.split(" ")[0]}</div>
                    <div className="vote-card__year">{currentCard.monthWinner.split(" ")[1]}</div>
                  </div>
                  <div></div>
                </div>
              )}

              {currentCard?.yearWinner && (
                <div className="vote-card">
                  <div className="vote-card__image-wrapper">
                    <div className="vote-card__image" style={{ backgroundImage: `url(${YearWinnerImage})` }} />
                  </div>
                  <div className="vote-card__month">{undefined}</div>
                  <div className="vote-card__year">{currentCard.yearWinner}</div>
                  <div></div>
                </div>
              )}

              {currentCard?.yearSilver && (
                <div className="vote-card">
                  <div className="vote-card__image-wrapper">
                    <div className="vote-card__image" style={{ backgroundImage: `url(${YearSilverImage})` }} />
                  </div>
                  <div className="vote-card__month">{undefined}</div>
                  <div className="vote-card__year">{currentCard.yearSilver}</div>
                  <div></div>
                </div>
              )}

              {currentCard?.yearBronze && (
                <div className="vote-card">
                  <div className="vote-card__image-wrapper">
                    <div className="vote-card__image" style={{ backgroundImage: `url(${YearBronzeImage})` }} />
                  </div>
                  <div className="vote-card__month">{undefined}</div>
                  <div className="vote-card__year">{currentCard.yearBronze}</div>
                  <div></div>
                </div>
              )}
            </div>
            <div className="nft-card__info_market-button">
              <Button>
                <a href={currentCard?.atomicHubLink} target="_blank" rel="noreferrer" style={{ fontSize: "24px" }}>
                  See on market
                </a>
              </Button>
            </div>
            <div className="nft-card__info_share-icons">
              <ShareBlock url={window.location.href} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
