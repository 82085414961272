import React, { useState } from "react";

import { Select, SelectProps } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as ArrowUp } from "assets/icons/select-open.svg";
import { ReactComponent as ArrowDown } from "assets/icons/select-close.svg";

import "./AntSelect.scss";

interface AntSelectProps extends SelectProps<any> {}

export const AntSelect: React.FC<AntSelectProps> = React.memo(({ children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openIcon = <Icon className="select-icon" component={ArrowUp} />;
  const closeIcon = <Icon className="select-icon" component={ArrowDown} />;
  return (
    <Select
      className="a-select"
      allowClear
      virtual={false}
      onDropdownVisibleChange={(open) => setIsOpen(open)}
      suffixIcon={isOpen ? openIcon : closeIcon}
      {...props}
    >
      {children}
    </Select>
  );
});
