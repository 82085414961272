import React from "react";

import { NftCardModel, ProfileModel } from "shared/http/ApiModels";

export const ProfileContext = React.createContext<{
  profile?: ProfileModel;
  votingCard: NftCardModel | null;
  voteCard: (cardId: NftCardModel) => Promise<void>;
}>({
  votingCard: null,
  voteCard: () => {
    throw new Error();
  },
});
