import React, {FC} from 'react';
import cs from 'classnames';

import {NftCardModel} from "shared/http/ApiModels";
import {googleDiskImageToWeb} from "shared/utils/googleDiskImageToWeb";
import YearMock from "assets/images/winners/year-mock.png";
import CardMock from "assets/images/winners/card-mock.png";

import {YearAwardeePlace} from "./types";

const PLACE_NAMES: Record<YearAwardeePlace, string> = {
    [YearAwardeePlace.Gold]: 'First place',
    [YearAwardeePlace.Silver]: 'Second place',
    [YearAwardeePlace.Bronze]: 'Third place'
}

type Props = {
    item?: NftCardModel;
    onClick: (id: string) => void;
    place: YearAwardeePlace;
}

export const YearAwardee: FC<Props> = ({item, onClick, place}) => {
    const itemId = item?.id ?? item?._id;
    const isEmpty = !itemId;
    const isWinner = place === YearAwardeePlace.Gold;

    const handleClick = () => {
        if (itemId) {
            onClick(itemId);
        }
    }

    const mainClass = isWinner ? "year-winner" : "year-sub-winner";

    const mockImg = isWinner ? YearMock : CardMock;
    const imageSource = item?.avatarLink ? googleDiskImageToWeb(item?.avatarLink) : mockImg;

    return (
        <div className={mainClass}>
            <div
                className={cs({
                    [`${mainClass}-image`]: true,
                    disabled: isEmpty
                })}
                onClick={handleClick}
            >
                <img
                    className={`${mainClass}-image__image`}
                    src={imageSource}
                    alt="image_year"
                />
            </div>
            <div className={cs({
                'winners-block__subtitle': isWinner,
                'winners-block__subtitle-left': !isWinner
            })}>{PLACE_NAMES[place]}</div>
        </div>
    );
};
