import React, { useCallback, useRef } from "react";

import { useGalleryCenter } from "core/context/GalleryCenter";
import { Pagination } from "shared/components/Pagination";
import { DEFAULT_PAGE_SIZE } from "shared/constants/paginated.constants";

import { GalleryFilters } from "./components/GalleryFilters";
import { GalleryItemCard } from "./components/GalleryItemCard";

import "./GalleryPage.scss";

export const GalleryPage: React.FC = React.memo(() => {
  const [galleryCenterState, galleryCenterActions] = useGalleryCenter();
  const headerRef = useRef<HTMLDivElement>(null);

  const toTop = useCallback(() => {
    headerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className="gallery-page text-page">
      <div className="gallery-page__header text-page__header">
        <div className="text-page__title text-border">gallery</div>
      </div>
      <div className="gallery-page__content text-page__content" ref={headerRef}>
        <GalleryFilters />
        <div className="gallery-page__cards-wrapper">
          <div className="gallery-page__cards">
            {galleryCenterState?.paginatedData?.map((item, index) => {
              console.log({ item, index });
              return <GalleryItemCard key={`${item.name}_${index}`} card={item} />;
            })}
          </div>
        </div>
        {(galleryCenterState.data.length ?? 0) > DEFAULT_PAGE_SIZE && (
          <div className="gallery-page__pagination">
            <Pagination
              onChange={galleryCenterActions.changePagination}
              total={galleryCenterState.data.length}
              pageSize={DEFAULT_PAGE_SIZE}
              current={galleryCenterState.page}
              toTop={toTop}
            />
          </div>
        )}
      </div>
    </div>
  );
});
