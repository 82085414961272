import React from "react";

import { HashRouter as Router } from "react-router-dom";

export function withRouter<T = any>(Component: React.FC<T>): React.FC<T> {
  return (props) => {
    return (
      <Router basename={""} hashType="noslash">
        <Component {...props} />
      </Router>
    );
  };
}
