import React, { useMemo, useCallback, useState } from "react";

import { Button, Select, Input } from "antd";

import { useGalleryCenter } from "core/context/GalleryCenter";
import { AntSelect } from "shared/components/AntSelect";

import { sortOptions } from "../../constants/sort.constants";

import "./SortSearchBlock.scss";

export const SortSearchBlock: React.FC = React.memo(() => {
  const [galleryCenterState, galleryCenterActions] = useGalleryCenter();
  const [searchString, setSearchString] = useState(galleryCenterState.filters?.searchString ?? "");

  const sortFieldOptions = useMemo(() => {
    return sortOptions.map(({ label, key, value }) => (
      <Select.Option value={value} key={key}>
        {label}
      </Select.Option>
    ));
  }, []);

  const handleSortSelect = useCallback(
    (value) => galleryCenterActions.changeFilters({ sort: value }),
    [galleryCenterActions]
  );

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchString(value ?? "");
  }, []);

  const handleSearch = useCallback(() => {
    galleryCenterActions.changeFilters({ searchString });
  }, [galleryCenterActions, searchString]);

  const handleInputEnterPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSearch();
      }
    },
    [handleSearch]
  );

  return (
    <div className="sort-search-block">
      <div className="sort-search-block__sort">
        <span className="sort-search-block__sort--label">Sort by</span>
        <AntSelect value={galleryCenterState.filters.sort} onSelect={handleSortSelect} allowClear={false}>
          {sortFieldOptions}
        </AntSelect>
      </div>
      <div className="sort-search-block__search">
        <Input
          placeholder="Search artist or NFT"
          value={searchString}
          onChange={handleInputChange}
          onKeyPress={handleInputEnterPress}
        />
        <Button onClick={handleSearch}>Search</Button>
      </div>
    </div>
  );
});
