import React from "react";

import { Modal, ModalFuncProps } from "antd";
import Icon from "@ant-design/icons";

import { ReactComponent as CloseIcon } from "assets/icons/modal-close.svg";

export const showCustomModal = (props: ModalFuncProps) => {
  return Modal.info({
    icon: null,
    className: "custom-modal",
    centered: true,
    ...props,
  });
};

export const showVoteSuccessModal = (props?: ModalFuncProps) =>
  showCustomModal({
    content: "Thank you for voting!",
    className: "vote-modal",
    closable: true,
    closeIcon: <Icon component={CloseIcon} className="modal-close-icon" style={{ fontSize: "20px" }} />,
    maskClosable: true,
    ...props,
  });
