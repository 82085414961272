import React, { useCallback, useEffect, useState } from "react";

import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { nanoid } from "nanoid";
import { NftCardModel, ProfileModel } from "shared/http/ApiModels";
import { getProfile, vote } from "shared/http/http.utils";
import { showVoteSuccessModal } from "shared/utils/modal.utils";

import { ProfileContext } from "./ProfileContext";

export const ProfileCenterProvider: React.FC = React.memo(({ children }) => {
  const [userId, setUserId] = useState<string>();
  const [profile, setProfile] = useState<ProfileModel>();
  const [votingCard, setVotingCard] = useState<NftCardModel | null>(null);

  useEffect(() => {
    const fingerprintId = localStorage.getItem("fingerprintId");
    if (!fingerprintId) {
      FingerprintJS.load({ token: "ARGw7OGbIWba86RxKucK" })
        .then((data) => data.get())
        .then((result) => {
          setUserId(result.visitorId);
          localStorage.setItem("fingerprintId", result.visitorId);
        })
        .catch(() => {
          const id = nanoid(10);
          setUserId(id);
          localStorage.setItem("fingerprintId", id);
        });
      return;
    }
    setUserId(fingerprintId);
  }, []);

  const fetchProfile = useCallback(async () => {
    if (!userId) return;
    try {
      const profile = await getProfile({ profileId: userId });
      if (profile) {
        setProfile(profile);
      }
    } catch (e) {
      console.log(e);
    }
  }, [userId]);

  const voteCard = useCallback(
    async (card: NftCardModel) => {
      if (!profile) return;
      setVotingCard(card);
      try {
        const updatedProfile = await vote({ cardId: card.id, profileId: profile.profileId });
        showVoteSuccessModal();
        setProfile(updatedProfile);
      } catch (e) {
        console.log(e);
      } finally {
        setVotingCard(null);
      }
    },
    [profile]
  );

  useEffect(() => {
    fetchProfile().catch();
  }, [fetchProfile]);

  return <ProfileContext.Provider value={{ profile, voteCard, votingCard }}>{children}</ProfileContext.Provider>;
});
